import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { fetchWithToken } from '../Util/fetch';
import MultiSelect from '../UIComponents/MultiSelect';

export default class StateForm extends Component {
  constructor(props) {
    super(props);
    this.state = { states: [], loading: true };
    this.stateHandler = this.stateHandler.bind(this);
  }

  componentDidMount() {
    fetchWithToken()
      .get('/state')
      .then(data => {
        const states = _.orderBy(
          data.data.map(state => ({ id: state.state_id, name: state.description, priority: state.priority })),
          'priority',
          'asec'
        );
        this.setState({ states, loading: false });
      });
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { state } = curVal;
    stateHandler('state', state.index, { value, index: state.index, valid: true });
  }

  render() {
    const { states, loading } = this.state;
    const { curVal } = this.props;
    return !loading ? (
      <MultiSelect
        formHandler={this.stateHandler}
        title="Select State"
        options={states}
        curVal={curVal.state.value}
        stateHandler={this.stateHandler}
        multi={false}
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

StateForm.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};
