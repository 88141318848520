import React, { Component } from 'react';
import { Menu, Loader, Segment, Button, Modal } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import NameSearch from '../ActivityComponents/nameSearch';
import StateForm from '../ActivityComponents/StateFrom';
import MethodForm from '../ActivityComponents/MethodForm';
import CBDForm from '../ActivityComponents/CBDForm';
import THCForm from '../ActivityComponents/THCForm';
import ClassificationForm from '../ActivityComponents/ClassificationForm';
import QuantityForm from '../ActivityComponents/QuantityFrom';
import MeasureForm from '../ActivityComponents/MeasureForm';
import ReasonForm from '../ActivityComponents/ReasonForm';
import ActivityEntryForm from '../ActivityComponents/ActivityEntryForm';
import TimeUse from '../ActivityComponents/TimeUse';
import PainTime from '../ActivityComponents/PainTime';
import ProductSourceForm from '../ActivityComponents/ProductSourceForm';
import LiquidForm from '../ActivityComponents/LiquidForm';
import { fetchWithToken } from '../Util/fetch';
import { ptSubAdd } from '../Util/helper';

const components = [
  {
    comp: NameSearch,
    active: true,
    text: 'NameSearch',
    name: 'name',
  },
  {
    comp: StateForm,
    active: false,
    text: 'StateForm',
    name: 'state',
  },
  {
    comp: MethodForm,
    active: false,
    text: 'MethodForm',
    name: 'method',
  },
  {
    comp: CBDForm,
    active: false,
    text: 'CBDForm',
    name: 'CBD',
  },
  {
    comp: THCForm,
    active: false,
    text: 'THCForm',
    name: 'THC',
  },
  {
    comp: ClassificationForm,
    active: false,
    text: 'ClassificationForm',
    name: 'classification',
  },
  {
    comp: QuantityForm,
    active: false,
    text: 'QuantityForm',
    name: 'quantity',
  },
  {
    comp: MeasureForm,
    active: false,
    text: 'MeasureForm',
    name: 'measure',
  },
  {
    comp: LiquidForm,
    active: false,
    text: 'LiquidForm',
    name: 'lc',
  },
  {
    comp: ReasonForm,
    active: false,
    text: 'ReasonForm',
    name: 'reason',
  },
  {
    comp: ProductSourceForm,
    active: false,
    text: 'ProductSourceForm',
    name: 'source',
  },
  {
    comp: PainTime,
    active: false,
    text: 'PainTime',
    name: 'pain',
  },
  {
    comp: TimeUse,
    active: false,
    text: 'TimeUse',
    name: 'time',
  },
];

const initState = {
  form: 'name',
  value: '',
  finished: false,
  components,
  index: 0,
  height: '',
  values: {
    name: {
      value: '',
      addon: false,
      index: 0,
    },
    state: {
      value: '',
      index: 1,
    },
    method: {
      method: '',
      device: '',
      index: 2,
    },
    CBD: {
      rating: null,
      rep: null,
      unit: null,
      index: 3,
    },
    THC: {
      rating: null,
      rep: null,
      unit: null,
      index: 4,
    },
    classification: {
      value: [],
      index: 5,
    },
    quantity: {
      value: '',
      unit: '',
      index: 6,
    },
    measure: {
      value: null,
      index: 7,
    },
    lc: {
      value: null,
      unit: null,
      percent: null,
      index: 8,
    },
    reason: {
      value: [],
      index: 9,
    },
    source: {
      value: '',
      rep: '',
      index: 10,
    },
    pain: {
      value: 0,
      index: 11,
    },
    time: {
      value: null,
      index: 12,
    },
  },
};

class ActivityForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id: '', values: null, form: null };
    this.submitHandler = this.submitHandler.bind(this);
    this.stateHandler = this.stateHandler.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const data = decoded.split('&');
    const studyID = data[1];

    // Checking if this form came from the "None" button
    const params = new URLSearchParams(this.props.location.search);
    const fromNone = params.get("fromnone") ? true : false;

    // Changed the url to get the participant study
    fetchWithToken()
      .get(`/participant/data/studyID/${studyID}`)
      .then(response => {
        const id = response.data.id;
        const study = response.data.study;
        this.setState({ id, study, fromNone });
      });
  }

  stateHandler(values, form) {
    if (values && form) {
      this.setState({ values, form });
    }
  }

  handleOpen() {
    const { values, form } = this.state;
    this.submitHandler(values, form);
    this.setState({ modalOpen: true });
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  submitHandler(values, form) {
    const { id, fromNone } = this.state;
    const { prev } = this.props;
    const activityTime = moment
      .utc()
      .subtract(prev ? 1 : 0, 'days')
      .format('YYYY-MM-DD HH:mm:ss');
    const activityDate = moment
      .utc()
      .subtract(prev ? 1 : 0, 'days')
      .format('YYYY-MM-DD');
    const substanceFields = {
      name: values.name.value,
      state_id: values.state.value ? values.state.value : null,
      thc_concentrate: values.THC.rep,
      thc_unit: values.THC.unit,
      cbd_concentrate: values.CBD.rep,
      cbd_unit: values.CBD.unit,
      thc_strength_id: values.THC.rep !== null ? null : values.THC.rating,
      cbd_strength_id: values.CBD.rep !== null ? null : values.CBD.rating,
      is_active: 1,
      is_study_approved: 0,
      classification_id: values.classification.value.join(),
      entry_date: activityTime,
    };
    fetchWithToken()
      .post('/substance/add', {
        fields: substanceFields,
      })
      .then(response => {
        const substanceID = response.data.id;
        const { addon } = values.name;
        const ptActivtyFields = {
          participant_id: id,
          activity_date: activityTime,
          // If this came from a "None" entry, mark it as completed
          status_id: fromNone ? 2 : 1,
          entry_date: activityTime,
          date: activityDate,
        };
        const ptActivtySubFields = {
          activity_time: values.time.value
            ? moment
                .utc(values.time.value)
                .subtract(prev ? 1 : 0, 'days')
                .format('YYYY-MM-DD HH:mm:ss')
            : null,
          participant_id: id,
          substance_id: substanceID,
          method_id: values.method.method ? values.method.method : null,
          device_id: values.method.device ? values.method.device : null,
          quantity: values.quantity.value ? values.quantity.value : null,
          unit_id: values.quantity.unit ? values.quantity.unit : null,
          reason_id: values.reason.value ? values.reason.value.join() : null,
          pain_rating_id: values.pain.value ? values.pain.value : null,
          measure: values.measure.value !== null ? values.measure.value : null,
          source: values.source.value ? values.source.value : null,
          source_rep: values.source.rep ? values.source.rep : null,
          lc: values.lc.value ? values.lc.value : null,
          lc_unit: values.lc.unit ? values.lc.unit : null,
          lc_percent: values.lc.percent ? values.lc.percent : null,
          is_active: 1,
          entry_date: activityTime,
          status_id: form ? 1 : 2,
          form_id: form || 12,
        };
        const visitIDUrl = `/pt_activity/visit_id/participant/${id}${prev ? '/1' : ''}`;
        const ptSubUrl = '/pt_activitysub/add';
        const ptActUrl = 'pt_activity/add';
        fetchWithToken()
          .get(visitIDUrl)
          .then(responseVis => {
            const dataVis = responseVis.data;
            if (dataVis.length === 1) {
              const visitID = dataVis[0].visit_id;
              ptActivtySubFields.visit_id = visitID;
              fetchWithToken().post(ptSubUrl, {
                fields: ptActivtySubFields,
              });
            } else {
              fetchWithToken()
                .post(ptActUrl, {
                  fields: ptActivtyFields,
                })
                .then(responseAct => {
                  const visitID = responseAct.data.visit_id;
                  ptActivtySubFields.visit_id = visitID;
                  fetchWithToken().post(ptSubUrl, {
                    fields: ptActivtySubFields,
                  });
                });
            }
          });
        if (addon) {
          ptSubAdd(substanceID, id);
        }
      });
  }

  render() {
    const resetComp = initState.components.map((comp, i) => ({
      comp: comp.comp,
      active: i < 1,
      text: comp.text,
      name: comp.name,
    }));

    initState.components = resetComp;
    // Added participant study to check for pain question
    // Added fromNone parameter to see if form came from "None" entry
    const { id, modalOpen, study, fromNone } = this.state;
    const { match, prev } = this.props;

    let linkBack = "/participant";
    // If form came from "None" entry, set form to done, and redirect to compliace at submission
    if (fromNone) {
      linkBack = "/compliance";
      initState.finished = true;
    }

    const saveTrigger = <Menu.Item name="Save" />;
    return id ? (
      <ActivityEntryForm
        initialState={initState}
        endForm="time"
        stateHandler={this.stateHandler}
        titleName="New Activity Entry"
        height="5000px"
        linkBack={linkBack}
        participantID={id}
        submitHandler={this.submitHandler}
        save
        prev={prev}
        pain={study === "CAMP"}
        nullSubmit={fromNone}
      >
        <Menu size="small" fluid fixed="top" widths={4}>
          <Menu.Item header as={Link} to={`/participant/${match.params.id}${prev ? '/prev' : ''}`} content="Home" />
          <Menu.Item as={Link} to={`/participant/${match.params.id}/recent${prev ? '/prev' : ''}`} name="Recent" />
          <Menu.Item
            as={Link}
            to={`/participant/${match.params.id}/typical${prev ? '/prev' : ''}`}
            name="Typical Activities"
          />
          <Modal
            trigger={saveTrigger}
            onOpen={this.handleOpen}
            open={modalOpen}
            onClose={this.handleClose}
            size="mini"
            dimmer
            name="Save"
          >
            <Modal.Header className="submit-confirmantion-header">Confirmation</Modal.Header>
            <Modal.Content>
              <h3 style={{ textAlign: 'center' }}>Entry was saved</h3>
            </Modal.Content>
            <Modal.Actions className="submit-from-modal-actions">
              <Modal.Header />
              <Link to={`/participant/${match.params.id}${prev ? '/prev' : ''}`}>
                <Button
                  style={{ textAlign: 'center' }}
                  size="small"
                  onClick={this.handleClose}
                  positive
                  icon="checkmark"
                  labelPosition="right"
                  content="OK"
                />
              </Link>
              <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Cancel" />
            </Modal.Actions>
          </Modal>
        </Menu>
      </ActivityEntryForm>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(ActivityForm);

ActivityForm.propTypes = {
  match: PropTypes.any,
  prev: PropTypes.bool,
};
