import React, { Component } from 'react';
import { Segment, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormHeader from './FormHeader';
import '../css/DropdownSearch.css';

export default class DropdownSearch extends Component {
  constructor(props) {
    super(props);
    const { optionData, curVal } = this.props;
    this.state = { options: optionData, currentValue: curVal, valid: true, newSub: false };
    this.handleAddition = this.handleAddition.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleAddition(e, { value }) {
    const { parentHandler } = this.props;
    const { options } = this.state;
    const maxKey = _.maxBy(options, o => o.key);
    this.setState({
      options: [{ key: maxKey + 2, value, text: value }, ...options],
      currentValue: value,
      newSub: true,
    });
    parentHandler(value, true);
  }

  handleChange(e, { value }) {
    const { parentHandler } = this.props;
    const { newSub } = this.state;
    if (value !== '') {
      this.setState({ currentValue: value });
      parentHandler(value, newSub);
    }
  }

  handleSearchChange(e, { searchQuery }) {
    if (searchQuery.replace(/\s/g, '').length === 0) {
      this.setState({ valid: false });
    } else {
      this.setState({ valid: true });
    }
  }

  render() {
    const { currentValue, options, valid } = this.state;
    const { title, text, customSearch, addon } = this.props;
    return (
      <Segment.Group size="mini">
        <Segment stacked color="blue">
          <FormHeader type="h3" title={title} />
          <Segment raised color="blue">
            <Dropdown
              options={options}
              placeholder={text}
              search={customSearch || true}
              selection
              fluid
              allowAdditions={addon && valid}
              value={currentValue}
              onAddItem={this.handleAddition}
              onChange={this.handleChange}
              onSearchChange={this.handleSearchChange}
              syle={{ minHeight: 'auto !important', height: '1em' }}
            />
          </Segment>
        </Segment>
      </Segment.Group>
    );
  }
}

DropdownSearch.propTypes = {
  curVal: PropTypes.any,
  text: PropTypes.string,
  addon: PropTypes.bool,
  optionData: PropTypes.array,
  title: PropTypes.string,
  parentHandler: PropTypes.func,
  customSearch: PropTypes.func,
};
