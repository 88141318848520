import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import AdminForm from './AdminComponents/AdminForm';
import AdminParticipantList from './AdminComponents/AdminParticipantList';
import AdminLogin from './AdminComponents/AdminLogin';
import Home from './ParticipantComponents/Home';
import VerifyParticipant from './ParticipantComponents/VerifyParticiapnt';
import ActivityForm from './ParticipantComponents/ActivityForm';
import PainForm from './ParticipantComponents/PainForm';
import EntryOption from './ParticipantComponents/EntryOptions';
import TypicalActivities from './ParticipantComponents/TypicalActivities';
import ReviewActivities from './ParticipantComponents/ReviewActivities';
import ActivityCompleteForm from './ParticipantComponents/ActivityCompleteForm';
import Compliance from './ParticipantComponents/Compliance';
import WeeklyForm from './ParticipantComponents/WeeklyForm';
import RecentActivities from './ParticipantComponents/RecentActivities';
import EditForm from './ParticipantComponents/EditForm';
import EditRecentActivities from './ParticipantComponents/EditRecentActivities';
import UnfinishedActivities from './ParticipantComponents/UnfinishedActivities';
import ScrollToTop from './UIComponents/ScrollToTop';
import ErrorPage from './UIComponents/ErrorPage';
import PrivateRoute from './Util/PrivateRoute';
import PrivateRouteAdmin from './Util/PrivateRouteAdmin';
import UpdateForm from './UIComponents/UpdateForm';
import InactiveList from './AdminComponents/InactiveList';
// import CampLayout from './UIComponents/CampLayout';
// import QuantityForm from './ActivityComponents/QuantityFrom';

class App extends Component {
  componentWillMount() {
    document.body.style.backgroundColor = '#4267B2';
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={AdminLogin} />
            <PrivateRouteAdmin path="/participants" component={AdminParticipantList} />
            <PrivateRouteAdmin exact path="/inactive" component={InactiveList} />
            <PrivateRouteAdmin exact path="/admin_entry/:id" component={AdminForm} />
            <PrivateRouteAdmin exact path="/admin/update/typical/:entryID" component={UpdateForm} isAdmin />
            <Route path="/verify/:id" component={VerifyParticipant} />
            <PrivateRoute exact path="/participant/:id" component={Home} />
            <PrivateRoute exact path="/participant/:id/entry" component={ActivityForm} />
            <PrivateRoute exact path="/participant/:id/pain/" component={PainForm} />
            <PrivateRoute exact path="/participant/:id/edit/:entryID" component={EditForm} />
            <PrivateRoute exact path="/participant/:id/typical" component={TypicalActivities} />
            <PrivateRoute exact path="/participant/:id/review" component={ReviewActivities} />
            <PrivateRoute exact path="/participant/:id/recent" component={RecentActivities} />
            <PrivateRoute exact path="/participant/:id/unfinished" component={UnfinishedActivities} />
            <PrivateRoute exact path="/participant/:id/recent/edit" component={EditRecentActivities} />
            <PrivateRoute exact path="/participant/:id/typical/update" component={TypicalActivities} update />
            <PrivateRoute exact path="/participant/:id/typical/update/:entryID" component={UpdateForm} />
            <PrivateRoute exact path="/participant/:id/options" component={EntryOption} />
            <PrivateRoute
              exact
              path="/participant/:id/complete/activity/:visitID/sub/:subID"
              component={ActivityCompleteForm}
            />
            <Route
              exact
              path="/expired"
              render={() => <ErrorPage header="Link has expired." message="Please Use The Most Recent Link." />}
            />
            <Route
              exact
              path="/unauthorized"
              render={() => (
                <ErrorPage header="Please log in." message="Please use the most recent link to access this page." />
              )}
            />
            <PrivateRoute exact path="/compliance/:id" component={Compliance} />
            <PrivateRoute exact path="/compliance/:id/prev" component={Compliance} />

            <PrivateRoute exact path="/participant/:id/weekly" component={WeeklyForm} />
            />
            <PrivateRoute prev exact path="/participant/:id/prev" component={Home} />
            <PrivateRoute prev exact path="/participant/:id/options/prev" component={EntryOption} />
            <PrivateRoute prev exact path="/participant/:id/entry/prev" component={ActivityForm} />
            <PrivateRoute prev exact path="/participant/:id/typical/prev" component={TypicalActivities} />
            <PrivateRoute prev exact path="/participant/:id/recent/prev" component={RecentActivities} />
            <PrivateRoute prev exact path="/participant/:id/review/prev" component={ReviewActivities} />
            <PrivateRoute
              prev
              exact
              path="/participant/:id/complete/activity/:visitID/sub/:subID/prev"
              component={ActivityCompleteForm}
            />
            <PrivateRoute prev exact path="/participant/:id/edit/:entryID/prev" component={EditForm} />
            <PrivateRoute exact path="/participant/:id/pain/prev" component={PainForm} />
            <Route render={() => <ErrorPage header="Page Not Found" />} />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
