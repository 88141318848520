import React, { Component } from 'react';
import { Button, Segment, List, Header, Message, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter, Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import TitleHeader from './TitleHeader';
import CampLayout from './CampLayout';
import FormHeader from './FormHeader';
// Addition of fetchWithToken for updating visit ID early on
import { fetchWithToken } from '../Util/fetch';

class ReviewActivityListView extends Component {
  constructor(props) {
    super(props);
    // Adding a loading state when updating visit ID
    this.state = { selected: '', id: '', error: false, valid: false, loading: false };
    this.handleActive = this.handleActive.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    // Function that will update the visit ID
    this.handleUpdateActivity = this.handleUpdateActivity.bind(this);
  }

  handleActive(selection) {
    const { selected } = this.state;
    return selection === selected;
  }

  handleOnClick(selection, id) {
    this.setState({ selected: selection, id });
  }

  handleNext() {
    const { selected, id } = this.state;
    const { ignorePainContent } = this.props;

    // If ignorePainContent is active, then following action is valid
    if (ignorePainContent || (selected !== '' && id !== '')) {
      this.setState({ error: false, valid: true });
    }
    else {
      this.setState({ error: true });
    }
  }

  async handleUpdateActivity(visitID) {
    // Set loading screen
    this.setState({ loading: true })
    const ptActFields = {
      status_id: 2,
    };
    // Update visit status to completed
    const data = await fetchWithToken().post(`/pt_activity/update/visit_id/${visitID}`, {
      values: ptActFields,
    });
    // When submission is confirmed, deactivate loading screen
    if (data) {
      this.setState({ loading: false })
    }
  }

  render() {
    const { activities, visitID, match, nextPage, children, title, subtitle, prev, ignorePainContent, hashID } = this.props;
    const { loading } = this.state;

    const errorMessage = (
      <Message negative>
        <Message.Header>Please Select An Entry</Message.Header>
      </Message>
    );
    const activityList = activities.map((activity, i) => (
      <List.Item
        active={this.handleActive(i)}
        onClick={() => this.handleOnClick(i, activity.id)}
        key={`review-activity-${activity.id}`}
      >
        {activity.substances.length && (
          <List.Header>
            {`${activity.substances[0].name} at ${moment(activity.activity_time).format('hh:mmA')}`}
          </List.Header>
        )}
      </List.Item>
    ));
    const participantID = match.params.id;
    const { id, valid, error } = this.state;

    // Addition of a loading screen
    if (loading) {
      return (
        <Segment>
          <Loader active inline="centered" />
        </Segment>
      )
    }

    // If ignorePainContent is active and user has clicked "Finish" then update
    //    the visit to completed and redirect to the compliance page
    if (ignorePainContent && valid) {
      this.handleUpdateActivity(visitID);
      return (
        <Redirect
          push
          to={`/compliance/${hashID}`}
        />
      );
    }

    if (valid) {
      return (
        <Redirect
          push
          to={`/participant/${participantID}/${nextPage}/activity/${visitID}/sub/${id}${prev ? '/prev' : ''}`}
        />
      );
    }
    // Removing selection if pain content is ignored
    return (
      <CampLayout height="100%">
        {children}
        <TitleHeader type="h1" title={title} />
        {error && errorMessage}
        {activityList.length ? (
          <Segment.Group size="mini">
            <Header as="h4" attached="top">
              {subtitle}
            </Header>
            <Segment attached stacked color="blue">
              <List size="massive" selection={!ignorePainContent} divided>
                {activityList}
              </List>
            </Segment>
            <Segment color="blue">
              <Button onClick={this.handleNext} positive content={ignorePainContent ? "Finish" : "Next"} />
              <Link to={`/participant/${participantID}${prev ? '/prev' : ''}`}>
                <Button negative content="Cancel" />
              </Link>
            </Segment>
          </Segment.Group>
        ) : (
          <Segment.Group color="blue">
            <Segment>
              <FormHeader type="h3" title="No Entries" />
              <Link to={`/participant/${participantID}${prev ? '/prev' : ''}`}>
                <Button fluid color="blue">
                  Go Back
                </Button>
              </Link>
            </Segment>
          </Segment.Group>
        )}
      </CampLayout>
    );
  }
}

export default withRouter(ReviewActivityListView);

ReviewActivityListView.propTypes = {
  activities: PropTypes.array,
  match: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.object,
  nextPage: PropTypes.string,
  visitID: PropTypes.number,
  prev: PropTypes.bool,
};
