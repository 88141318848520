import React, { Component } from 'react';
import { Menu, Segment, Loader } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import NameSearch from '../ActivityComponents/nameSearch';
import StateForm from '../ActivityComponents/StateFrom';
import MethodForm from '../ActivityComponents/MethodForm';
import CBDForm from '../ActivityComponents/CBDForm';
import THCForm from '../ActivityComponents/THCForm';
import ClassificationForm from '../ActivityComponents/ClassificationForm';
import QuantityForm from '../ActivityComponents/QuantityFrom';
import ReasonForm from '../ActivityComponents/ReasonForm';
import ActivityEntryForm from '../ActivityComponents/ActivityEntryForm';
import MeasureForm from '../ActivityComponents/MeasureForm';
import ProductSourceForm from '../ActivityComponents/ProductSourceForm';
import { fetchWithToken } from '../Util/fetch';
import { ptSubAdd } from '../Util/helper';

const components = [
  {
    comp: NameSearch,
    active: true,
    text: 'NameSearch',
    name: 'name',
  },
  {
    comp: StateForm,
    active: true,
    text: 'StateForm',
    name: 'state',
  },
  {
    comp: MethodForm,
    active: true,
    text: 'MethodForm',
    name: 'method',
  },
  {
    comp: CBDForm,
    active: true,
    text: 'CBDForm',
    name: 'CBD',
  },
  {
    comp: THCForm,
    active: true,
    text: 'THCForm',
    name: 'THC',
  },
  {
    comp: ClassificationForm,
    active: true,
    text: 'ClassificationForm',
    name: 'classification',
  },
  {
    comp: QuantityForm,
    active: true,
    text: 'QuantityForm',
    name: 'quantity',
  },
  {
    comp: MeasureForm,
    active: true,
    text: 'MeasureForm',
    name: 'measure',
  },
  {
    comp: ReasonForm,
    active: true,
    text: 'ReasonForm',
    name: 'reason',
  },
  {
    comp: ProductSourceForm,
    active: true,
    text: 'ProductSourceForm',
    name: 'source',
  },
];

const initState = {
  form: 'name',
  value: '',
  finished: true,
  components,
  index: 9,
  height: '',
};

class UpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id: '', initValues: null };
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { entryID } = match.params;

    const url = `/pt_profile/id/${entryID}`;

    fetchWithToken()
      .get(url)
      .then(response => {
        const data = response.data[0];
        const substance = data.substances[0];
        const initValues = {
          name: {
            value: substance.name,
            addon: false,
            index: 0,
          },
          state: {
            index: 1,
            value: substance.state_id,
          },
          method: {
            method: data.method_id,
            device: data.device_id,
            index: 2,
            valid: true,
          },
          CBD: {
            rating: substance.cbd_strength_id,
            rep: substance.cbd_concentrate,
            unit: substance.cbd_unit,
            index: 3,
            edit: true,
          },
          THC: {
            rating: substance.thc_strength_id,
            rep: substance.thc_concentrate,
            unit: substance.thc_unit,
            index: 4,
            edit: true,
          },
          classification: {
            index: 5,
            value: substance.classification_id.split(',').map(id => Number(id)),
            edit: true,
          },
          quantity: {
            value: data.quantity !== null ? data.quantity : null,
            unit: data.unit_id,
            index: 6,
          },
          measure: {
            index: 7,
            value: data.measure !== null ? data.measure : null,
          },
          reason: {
            index: 8,
            value: data.reason_id.split(',').map(id => Number(id)),
            edit: true,
          },
          source: {
            value: data.source !== null ? data.source : null,
            rep: data.source_rep ? data.source_rep : null,
            index: 9,
            valid: true,
          },
        };
        this.setState({ initValues });
        const { location } = this.props;
        if (location.state && location.state.participantID) {
          this.setState({ id: location.state.participantID });
        } else {
          const hased = match.params.id;
          const decoded = atob(hased);
          const splitData = decoded.split('&');
          const studyID = splitData[1];
          fetchWithToken()
            .get(`/participant/id/studyID/${studyID}`)
            .then(responseID => {
              const id = responseID.data;
              this.setState({ id, initValues });
            });
        }
      });
  }

  handleUpdate(values) {
    const { id } = this.state;
    const { location, match } = this.props;
    const { entryID } = match.params;
    let subtanceID;
    if (location.state && location.state.subID) {
      subtanceID = location.state.subID;
    }
    const subtanceURL = `/substance/update/${subtanceID}`;
    const ptProfileUrl = `/pt_profile/update/id/${entryID}`;
    const { addon } = values.name;
    const filterClassification = values.classification.value.filter(classID => classID !== 0);
    const updateSub = () => {
      const substanceFields = {
        name: values.name.value,
        state_id: values.state.value,
        thc_concentrate: values.THC.rep,
        thc_unit: values.THC.unit,
        cbd_concentrate: values.CBD.rep,
        cbd_unit: values.CBD.unit,
        thc_strength_id: values.THC.rating,
        cbd_strength_id: values.CBD.rating,
        classification_id: filterClassification.join(),
      };
      return fetchWithToken().post(subtanceURL, {
        fields: substanceFields,
      });
    };

    const updatePtActSub = () => {
      const ptProfileFields = {
        method_id: values.method.method,
        device_id: values.method.device ? values.method.device : null,
        quantity: values.quantity.value,
        unit_id: values.quantity.unit,
        reason_id: values.reason.value.join(),
        measure: values.measure.value,
        source: values.source.value,
        source_rep: values.source.rep,
      };
      return fetchWithToken().post(ptProfileUrl, {
        values: ptProfileFields,
      });
    };

    axios.all([updatePtActSub(), updateSub()]);

    if (addon) {
      ptSubAdd(subtanceID, id);
    }
  }

  render() {
    const { initValues, form, id } = this.state;
    const { isAdmin, match } = this.props;
    initState.values = initValues;
    if (form) {
      const updatedComps = components.map((comp, i) => ({
        comp: comp.comp,
        active: i <= form,
        text: comp.text,
        name: comp.name,
      }));
      initState.components = updatedComps;
      if (form !== 12) {
        initState.index = form;
        initState.finished = false;
        initState.form = updatedComps[form].name;
      } else {
        initState.finished = true;
      }
    } else {
      const updatedComps = components.map(comp => ({
        comp: comp.comp,
        active: true,
        text: comp.text,
        name: comp.name,
      }));
      initState.components = updatedComps;
      initState.finished = true;
    }

    return initValues ? (
      <ActivityEntryForm
        initialState={initState}
        endForm="soruce"
        titleName="Update Typical Activity"
        height="4200px"
        linkBack={isAdmin ? '/participants' : '/participant'}
        participantID={id}
        submitHandler={this.handleUpdate}
      >
        {isAdmin ? (
          <Menu fixed="top" color="blue" widths={2}>
            <Menu.Item content="CAMP" />
            <Menu.Item as={Link} to="/participants" content="Participants" />
          </Menu>
        ) : (
          <Menu fixed="top" color="blue" widths={1}>
            <Menu.Item as={Link} to={`/participant/${match.params.id}`} content="Home" />
          </Menu>
        )}
      </ActivityEntryForm>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(UpdateForm);

UpdateForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object,
  isAdmin: PropTypes.bool,
};
