import React, { Component } from 'react';
import { Transition, List, Segment, Button } from 'semantic-ui-react';
import { Element, scroller } from 'react-scroll';
import update from 'immutability-helper';
import { ReactHeight } from 'react-height';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import TitleHeader from '../UIComponents/TitleHeader';
import '../css/ActivityEntryForm.css';
import CampLayout from '../UIComponents/CampLayout';
import SubmitFormButton from '../UIComponents/SubmitFormButton';
import FormHeader from '../UIComponents/FormHeader'
import { checkValues } from '../Util/helper';

class ActivityEntryForm extends Component {
  constructor(props) {
    super(props);
    const { initialState } = this.props;
    this.state = initialState;
    this.stateHandler = this.stateHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    const { index, form, activeForm } = this.state;
    const elementName = `elem-${index}`;
    if (index !== 0 && form === activeForm) {
      scroller.scrollTo(elementName, {
        duration: 800,
        delay: 100,
        smooth: true,
        offset: -40,
      });
    }
  }

  handleSubmit() {
    const { values } = this.state;
    const { submitHandler } = this.props;
    submitHandler(values);
  }

  stateHandler(form, question, value) {
    const { components, form: stateForm } = this.state;

    const { length } = components;
    // Added the pain parameter to check if pain questions should be added
    const { save, weekly, endForm, stateHandler, pain } = this.props;
    const newState = update(this.state, {
      activeForm: { $set: form },
      values: { [form]: { $set: value } },
    });
    this.setState(newState);
    if (save) {
      stateHandler(newState.values, newState.index);
    }
    if (form === endForm && !weekly) {
      this.setState({ value, finished: true });
      return;
    }

    if (weekly) {
      if (value.done === 'yes') {
        this.setState({ value, finished: true });
      } else {
        this.setState({ value, finished: false });
      }
      return;
    }
    if (stateForm === form && (pain || value.valid === true)) {
      this.setState(prevState => {
        let nextQuestion = Number(question) + 1;
        const comps = prevState.components;
        let extraJumps = 0
        // If pain question is not asked, skip to the next question available
        if (comps[nextQuestion].name === "pain" && !pain) {
          nextQuestion += 1;
          extraJumps = 1;
        }
        comps[nextQuestion].active = true;
        // Update the index if skipped questions
        const newIndex = prevState.index + 1 + extraJumps < length ? prevState.index + 1 + extraJumps : prevState.index;
        const state = {
          form: comps[nextQuestion].name,
          activeForm: comps[nextQuestion].name,
          index: newIndex,
          components: comps,
        };
        if (save) {
          stateHandler(newState.values, state.index);
        }
        return state;
      });
    }
  }

  render() {
    // Added pain parameter to see if it should skip pain questions
    // Added nullSubmit parameter to see if it should submit an "None" entry
    const { height, children, titleName, participantID, match, linkBack, pain, weekly, prev, nullSubmit } = this.props;
    const { components, values, index, finished } = this.state;
    const formLink = match.params.id
      ? `${linkBack}/${match.params.id}${prev ? '/prev' : ''}`
      : `${linkBack}${prev ? '/prev' : ''}`;
    // If is a "None" entry, just to display confirmation
    if (nullSubmit) {
      const submit = (
        <div className="entry-submit">
          <SubmitFormButton
            submit
            text="Confirm"
            linkBack={formLink}
            submitForm={this.handleSubmit}
            closeButton={false}
            active
            floatPos="center"
          />
        </div>
      )

      return (
        <CampLayout height="100%">
          <Segment.Group size="mini">
            <Segment stacked color="blue">
              <FormHeader
                type="h3"
                title="Click Confirm to submit a No Activity entry"
              />
              {submit}
              <Link to={`/participant/${match.params.id}${prev ? '/prev' : ''}`}>
                <Button negative content="Cancel" />
              </Link>
            </Segment>
          </Segment.Group>
        </CampLayout>
      )
    }
    return (
      <CampLayout height={height}>
        {children}
        <TitleHeader type="h2" title={titleName} />
        <Transition.Group animation="slide down" duration={800}>
          {components.map(
            (Comp, i) =>
              Comp.active && (
                <List.Item key={`key-comp-${Comp.name}`}>
                  <Element name={`elem-${i}`}>
                    <ReactHeight
                      onHeightReady={compHeight => {
                        if (index === i && compHeight > 0) {
                          this.setState({ height: compHeight });
                        }
                      }}
                    >
                      <Comp.comp
                        className={`comp-${i}`}
                        curVal={values}
                        stateHandler={this.stateHandler}
                        participantID={Number(participantID)}
                        pain={pain}
                      />
                    </ReactHeight>
                  </Element>
                </List.Item>
              )
          )}
          {finished && (!weekly && !pain) ? (
            <div className="entry-submit">
              <SubmitFormButton
                submit
                text="Submit"
                linkBack={formLink}
                submitForm={this.handleSubmit}
                closeButton={false}
                active={checkValues(values)}
              />
            </div>
          ) : (
            finished && (
              <div className="entry-submit">
                <SubmitFormButton
                  submit
                  text="Submit"
                  linkBack={formLink}
                  submitForm={this.handleSubmit}
                  closeButton={false}
                  active
                />
              </div>
            )
          )}
        </Transition.Group>
      </CampLayout>
    );
  }
}
export default withRouter(ActivityEntryForm);

ActivityEntryForm.propTypes = {
  initialState: PropTypes.object,
  endForm: PropTypes.string,
  titleName: PropTypes.string,
  children: PropTypes.object,
  height: PropTypes.string,
  participantID: PropTypes.any,
  linkBack: PropTypes.string,
  submitHandler: PropTypes.func,
  match: PropTypes.any,
  weekly: PropTypes.bool,
  stateHandler: PropTypes.func,
  save: PropTypes.bool,
  pain: PropTypes.bool,
  prev: PropTypes.bool,
};
