import React from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CampLayout from './CampLayout';

const ErrorPage = ({ header, message }) => (
  <CampLayout>
    <Message negative>
      <Message.Header>{header}</Message.Header>
      <p>{message}</p>
    </Message>
  </CampLayout>
);

ErrorPage.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
};

export default ErrorPage;
