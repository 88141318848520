import React from 'react';
import { Button, Segment, List, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import TitleHeader from './TitleHeader';
import FormHeader from './FormHeader';
import '../css/ActivityList.css';
import CampLayout from './CampLayout';

const ActivityList = ({ title, children, subtitle, activities, typicalUpdate, match, blocked, dates, prev }) => (
  <CampLayout height="100%">
    {children}
    <TitleHeader type="h1" title={title} />
    {activities.length ? (
      <Segment.Group size="mini">
        {blocked ? (
          activities.map((act, i) => (
            <React.Fragment key={`act-past-${dates[i]}`}>
              <Header as="h2" attached="top">
                {`Entries for ${
                  moment().diff(dates[i], 'days') === 0
                    ? 'Today'
                    : moment().diff(dates[i], 'days') === 1
                      ? 'Yesterday'
                      : '2 days ago'
                }`}
              </Header>
              <Segment attached stacked color="blue">
                <List size="massive" selection divided>
                  {act}
                </List>
              </Segment>
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <Header as="h2" attached="top">
              {subtitle}
            </Header>
            <Segment attached stacked color="blue">
              <List size="massive" selection divided>
                {activities}
              </List>
            </Segment>
          </React.Fragment>
        )}
      </Segment.Group>
    ) : (
      <Segment.Group color="blue">
        <Segment>
          <FormHeader type="h2" title="No Entries" />
          <Link
            to={
              typicalUpdate
                ? `/participant/${match.params.id}`
                : `/participant/${match.params.id}/options${prev ? '/prev' : ''}`
            }
          >
            <Button className="activity-list-back" fluid>
              Go Back
            </Button>
          </Link>
        </Segment>
      </Segment.Group>
    )}
  </CampLayout>
);

export default withRouter(ActivityList);

ActivityList.propTypes = {
  activities: PropTypes.array,
  match: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.object,
  typicalUpdate: PropTypes.bool,
  blocked: PropTypes.bool,
  dates: PropTypes.array,
  prev: PropTypes.bool,
};
