import React, { Component } from 'react';
import { Grid, Segment, Form, Message, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import TitleHeader from '../UIComponents/TitleHeader';
import fetch from '../Util/fetch';
import '../css/AdminLogin.css';

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = { token: '', validated: false, username: '', password: '', authFailed: false };
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleUserNameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit() {
    const { username, password } = this.state;
    fetch
      .post('/users/login/', {
        username,
        password,
      })
      .then(response => {
        const { data } = response;
        if (data.auth) {
          this.setState({ validated: true, token: data.token });
        } else if (response.status === 401) {
          this.setState({ authFailed: true });
        }
      })
      .catch((error) => {
        this.setState({ authFailed: true, custom: error });
      });
  }

  render() {
    const { token, validated, authFailed } = this.state;
    const { location } = this.props;

    const error = (
      <Message negative>
        <Message.Header>Wrong Password or Username</Message.Header>
      </Message>
    );

    const unauthMessage = (
      <Message negative>
        <Message.Header>Please Login To View This Page</Message.Header>
      </Message>
    );

    if (validated) {
      sessionStorage.setItem('token', token);
      return <Redirect push to="/participants/" />;
    }
    return (
      <div className="admin-login-form">
        <style>{`
                body > div,
                body > div > div,
                body > div > div > div.admin-login-form {
                    height: 100%;
                    }
                `}</style>
        <Grid className="admin-login-grid" textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 375 }}>
            {location.state && location.state.logout && <Message positive header="You have logged out" />}
            {location.state && location.state.logout && sessionStorage.clear()}
            {authFailed && error}
            {location.state && location.state.unauth && unauthMessage}
            <TitleHeader type="h1" color="blue" title="Camp Login" />

            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Enter AD Username"
                  onChange={this.handleUserNameChange}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Enter Password"
                  type="password"
                  onChange={this.handlePasswordChange}
                />
                <Button className="admin-login-button" type="submit" fluid size="large">
                  Login
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(AdminLogin);

AdminLogin.propTypes = {
  location: PropTypes.object,
};
