import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { adminAuth } from './auth';

class PrivateRouteAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { auth: '' };
  }

  componentDidMount() {
    adminAuth()
      .then(resp => {
        const authed = resp.data.auth;
        this.setState({ auth: authed });
      })
      .catch(() => {
        this.setState({ auth: false });
      });
  }

  render() {
    const { auth } = this.state;
    const { component: Component, ...rest } = this.props;
    return (
      auth !== '' && (
        <Route
          {...rest}
          render={() =>
            auth === true ? <Component {...rest} /> : <Redirect to={{ pathname: '/', state: { unauth: true } }} />
          }
        />
      )
    );
  }
}

PrivateRouteAdmin.propTypes = {
  component: PropTypes.func,
};

export default withRouter(PrivateRouteAdmin);
