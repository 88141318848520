import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ButtonForm from './ButtonForm';

export default class ButtonGroupForm extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = { option: curVal };
    this.buttonClickHandler = this.buttonClickHandler.bind(this);
    this.activeHandler = this.activeHandler.bind(this);
  }

  buttonClickHandler(option) {
    this.setState({ option });
    const { formStateUpdate } = this.props;
    formStateUpdate(option);
  }

  activeHandler(actuiveOption) {
    const { option } = this.state;
    if (actuiveOption === option) {
      return true;
    }

    return false;
  }

  render() {
    const { options } = this.props;
    const optionList = options.map(option => (
      <ButtonForm
        key={option.id}
        value={option.id}
        buttonName={option.name}
        formClickHandler={() => this.buttonClickHandler(option.id)}
        activeState={this.activeHandler(option.id)}
      />
    ));

    return (
      <Button.Group fluid basic vertical>
        {optionList}
      </Button.Group>
    );
  }
}

ButtonGroupForm.propTypes = {
  curVal: PropTypes.any,
  options: PropTypes.array,
  formStateUpdate: PropTypes.func,
};
