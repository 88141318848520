import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import InputAndDropdown from '../UIComponents/InputAndDropdown';
import { fetchWithToken } from '../Util/fetch';

export default class QuantityForm extends Component {
  constructor(props) {
    super(props);
    this.state = { units: [], loading: true };
    this.stateHandler = this.stateHandler.bind(this);
  }

  async componentDidMount() {
    const { data: rawUnits } = await fetchWithToken().get('/unit');
    const units = _.orderBy(rawUnits, 'priority', 'asec').map(unit => ({
      key: unit.unit_id,
      text: unit.description,
      value: unit.unit_id,
    }));

    this.setState({ units, loading: false });
  }

  stateHandler(value, unit, valid) {
    const { curVal, stateHandler } = this.props;
    const { quantity } = curVal;
    console.log(`Unit is ${unit}`);
    stateHandler('quantity', quantity.index, { value, unit, index: quantity.index, valid });
  }

  render() {
    const { units, loading } = this.state;
    const { curVal } = this.props;
    return !loading ? (
      <InputAndDropdown
        curVal={curVal.quantity}
        title="Enter Quantity"
        text="Enter Unit"
        stateHandler={this.stateHandler}
        placeholder="Enter the quantity"
        options={units}
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

QuantityForm.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};
