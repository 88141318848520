import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import { fetchWithToken } from '../Util/fetch';
import WeeklyConditional from './WeeklyConditional';

export default class WeeklyQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [{ id: 1, name: 'No' }, { id: 2, name: 'Yes' }],
      treatments: [],
      treatmentsOther: [],
      loading: true,
    };
    this.stateHandler = this.stateHandler.bind(this);
  }

  componentDidMount() {
    fetchWithToken()
      .get('/substance/other')
      .then(response => {
        const data = response.data.map(option => ({
          id: option.subother_id,
          name: option.description,
        }));
        const treatments = data.splice(0, 4);
        const treatmentsOther = data.splice(0);
        this.setState({ treatments, treatmentsOther, loading: false });
      });
  }

  stateHandler(value) {
    const { stateHandler } = this.props;
    stateHandler('weekly', '0', value);
  }

  render() {
    const { treatments, options, treatmentsOther, loading } = this.state;
    const { curVal, pain } = this.props;

    // Using the pain attribute from current component to update the title
    const painSuffix = pain ? "treatment to relieve your pain" : 'substances';
    const title = `Did you use any other ${painSuffix} this week?`;
    return !loading ? (
      <WeeklyConditional
        formHandler={this.stateHandler}
        title={title}
        options={options}
        curVal={curVal.weekly}
        multi={false}
        optionData={treatments}
        moreData={treatmentsOther}
        text="Select Treatment(s)"
        conditional
        conditionalValues={[2]}
        secondConditionalValues={4}
        lastConditionalValue={2}
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

WeeklyQuestion.propTypes = {
  stateHandler: PropTypes.func,
  curVal: PropTypes.any,
};
