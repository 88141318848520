import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Loader, Segment, Menu } from 'semantic-ui-react';
import { fetchWithToken } from '../Util/fetch';
import ReviewActivityListView from '../UIComponents/ReviewActivityListView';
import '../css/ReviewActivities.css';

class ReviewActivities extends Component {
  constructor(props) {
    super(props);
    this.state = { activities: [], loading: true };
  }

  componentDidMount() {
    const { match, prev } = this.props;

    const hased = match.params.id;
    const decoded = atob(hased);
    const splitData = decoded.split('&');
    const studyID = splitData[1];

    // Checking if this form will ignore pain content
    const params = new URLSearchParams(this.props.location.search);
    const ignorePainContent = params.get("ignorepaincontent") ? true : false;

    fetchWithToken()
      .get(`/participant/id/studyID/${studyID}`)
      .then(response => {
        const id = response.data;
        const visitIDUrl = `/pt_activity/review/participant/${id}${prev ? '/1' : ''}`;
        fetchWithToken()
          .get(visitIDUrl)
          .then(responseVisit => {
            const { data } = responseVisit;
            if (data.length >= 1) {
              this.setState({ activities: data, loading: false, ignorePainContent });
            } else {
              this.setState({ loading: false });
            }
          });
      });
  }

  render() {
    const { match, prev } = this.props;
    // Adding ignorePainContent to check if it should skip right to compliance
    const { activities, loading, ignorePainContent } = this.state;

    // Moving loading rendering for early return
    if (loading) {
      return (
        <Segment>
          <Loader active inline="centered" />
        </Segment>
      )
    }

    // Possible subtitles for review activities page
    const subtitleWithActivities = `Please select which of these activities had the greatest effect on your pain level.${
      prev ? ' Please answer the following questions on the next page as if it were the previous day' : ''
    }`
    const subtitleWithoutActivities = "Please select which of these activities had the greatest effect on your pain level."
    const subtitleIgnorePainContent = "Click Finish to submit your entry";

    // Passing ignorePainContent and hashID in order to be use for compliance if pain contents are ignored
    return activities.length > 0 ? (
      <ReviewActivityListView
        activities={activities[0].pt_activitysubs}
        title={`Activities Entered ${prev ? 'For Previous Day' : 'Today'}`}
        subtitle={!ignorePainContent ? subtitleWithActivities : subtitleIgnorePainContent}
        nextPage="complete"
        backPage="participant"
        visitID={activities[0].visit_id}
        prev={prev}
        ignorePainContent={ignorePainContent}
        hashID={match.params.id}
      >
        <Menu fixed="top" color="blue" widths={1}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}${prev ? '/prev' : ''}`} content="Home" />
        </Menu>
      </ReviewActivityListView>
    ) : (
      <ReviewActivityListView
        activities={[]}
        title="No Activities Entered Today"
        subtitle={!ignorePainContent ? subtitleWithoutActivities : subtitleIgnorePainContent}
        nextPage="complete"
        backPage="participant"
        visitID=""
        ignorePainContent={ignorePainContent}
        hashID={match.params.id}
      >
        <Menu fixed="top" color="blue" widths={1}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}${prev ? '/prev' : ''}`} content="Home" />
        </Menu>
      </ReviewActivityListView>
    );
  }
}

export default withRouter(ReviewActivities);

ReviewActivities.propTypes = {
  match: PropTypes.object,
  prev: PropTypes.bool,
};
