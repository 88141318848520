import React, { Component } from 'react';
import { Segment, Label, Modal, Button, List, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter, Redirect } from 'react-router-dom';
import FormHeader from './FormHeader';
import '../css/ActivityEntryView.css';
import { fetchWithToken } from '../Util/fetch';
import AdditionalForm from './AdditionalForm';

class ActivityEntryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      strength: [],
      activityReasons: [],
      sources: [],
      edit: false,
      updated: false,
      finished: false,
      typicalRedirect: false,
      id: '',
      loading: false,
      // Adding the isPainContent to check if pain confirmation should be displayed
      isPainContent: true,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleFinish = this.handleFinish.bind(this);
    this.handleTypicalRedirect = this.handleTypicalRedirect.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const splitData = decoded.split('&');
    const studyID = splitData[1];
    this.setState({ loading: true });
    // Updated fetch url to get pt study for isPainContent update
    const response = await fetchWithToken().get(`/participant/data/studyID/${studyID}`);
    const { id, study } = response.data
    const { data: strength } = await fetchWithToken().get('/strength');
    const { data: activityReasons } = await fetchWithToken().get('/activity_reason');
    const { data: sources } = await fetchWithToken().get('/product_source/');
    // Update isPainContent
    this.setState({ id, strength, activityReasons, sources, loading: false, isPainContent: study === "CAMP" });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleEdit() {
    this.setState({ edit: true });
  }

  handleUpdate() {
    this.setState({ updated: true });
  }

  handleFinish() {
    this.setState({ finished: true });
  }

  handleTypicalRedirect() {
    this.setState({ typicalRedirect: true });
  }

  handleSubmit(value) {
    const { id } = this.state;
    const { entry, prev } = this.props;
    const activityTime = moment
      .utc()
      .subtract(prev ? 1 : 0, 'days')
      .format('YYYY-MM-DD HH:mm:ss');
    const activityDate = moment
      .utc()
      .subtract(prev ? 1 : 0, 'days')
      .format('YYYY-MM-DD');
    const checkPtActivityRoute = `/pt_activity/visit_id/participant/${id}${prev ? '/1' : ''}`;
    const ptSubUrl = '/pt_activitysub/add';
    const PtActAddUrl = '/pt_activity/add';
    fetchWithToken()
      .get(checkPtActivityRoute)
      .then(response => {
        const dataActivity = response.data;
        const ptActivitySubFields = {
          activity_time: moment
            .utc(value.time)
            .subtract(prev ? 1 : 0, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
          substance_id: entry.substance_id,
          method_id: entry.method_id,
          device_id: entry.device_id,
          quantity: entry.quantity,
          unit_id: entry.unit_id,
          reason_id: entry.reason_id,
          pain_rating_id: value.pain,
          entry_date: activityTime,
          measure: entry.measure,
          source: entry.source,
          source_rep: entry.source_rep,
          is_active: 1,
          status_id: 2,
          form_id: 12,
        };
        if (dataActivity.length === 1) {
          const visitID = dataActivity[0].visit_id;
          ptActivitySubFields.visit_id = visitID;
          fetchWithToken().post(ptSubUrl, {
            fields: ptActivitySubFields,
          });
        } else {
          const ptActivityFields = {
            participant_id: id,
            activity_date: activityTime,
            status_id: 1,
            entry_date: activityTime,
            date: activityDate,
          };
          fetchWithToken()
            .post(PtActAddUrl, {
              fields: ptActivityFields,
            })
            .then(responseAct => {
              const { data } = responseAct;
              const visitID = data.visit_id;
              ptActivitySubFields.visit_id = visitID;
              fetchWithToken().post(ptSubUrl, {
                fields: ptActivitySubFields,
              });
            });
        }
      });
  }

  render() {
    // Adding the isPainContent to check if pain confirmation should be displayed
    const { edit, updated, finished, activityReasons, sources, typicalRedirect, strength, open, loading, isPainContent } = this.state;
    const {
      entryID,
      entry,
      typicalUpdate,
      finish,
      update,
      participantID,
      classifications,
      editType,
      title,
      header,
      prev,
    } = this.props;
    const subID = entry.substance_id;
    if (edit) {
      return (
        <Redirect
          push
          to={{
            pathname: `/participant/${participantID}/edit/${entryID}${prev ? '/prev' : ''}`,
            state: { editType },
          }}
        />
      );
    }
    if (updated) {
      return (
        <Redirect
          push
          to={{
            pathname: `/participant/${participantID}/edit/${entryID}${prev ? '/prev' : ''}`,
            state: { editType, update: true, subID },
          }}
        />
      );
    }

    if (finished) {
      return (
        <Redirect
          push
          to={{
            pathname: `/participant/${participantID}/edit/${entryID}${prev ? '/prev' : ''}`,
            state: { editType, ptSubID: entryID, subID, update: true },
          }}
        />
      );
    }

    if (typicalRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: `/participant/${participantID}/typical/update/${entryID}`,
            state: { subID },
          }}
        />
      );
    }
    const entryView = (
      <Segment.Group>
        <Segment>
          <FormHeader type="h3" title={title} />
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Name
          </Label>
          {entry.substances[0].name}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            State
          </Label>
          {entry.substances[0].state_lks.length ? entry.substances[0].state_lks[0].description : ''}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Method
          </Label>
          {entry.method_lks.length ? entry.method_lks[0].description : ''}
        </Segment>
        {entry.device_lks &&
          entry.device_lks.length !== 0 && (
            <Segment>
              <Label color="blue" ribbon>
                Device
              </Label>
              {entry.device_lks[0].description}
            </Segment>
          )}
        <Segment>
          <Label color="blue" ribbon>
            CBD
          </Label>
          {entry.substances[0].cbd_concentrate !== null
            ? entry.substances[0].cbd_concentrate === 999
              ? 'Unknown'
              : `${entry.substances[0].cbd_concentrate} ${entry.substances[0].cbd_unit}`
            : entry.substances[0].cbd_strength_id
              ? strength.length && strength[entry.substances[0].cbd_strength_id - 1].description
              : ''}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            THC
          </Label>
          {entry.substances[0].thc_concentrate !== null
            ? entry.substances[0].thc_concentrate === 999
              ? 'Unknown'
              : `${entry.substances[0].thc_concentrate} ${entry.substances[0].thc_unit}`
            : entry.substances[0].thc_strength_id
              ? strength.length && strength[entry.substances[0].thc_strength_id - 1].description
              : ''}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Classification
          </Label>
          {classifications.length &&
            entry.substances[0].classification_id !== '' &&
            entry.substances[0].classification_id
              .split(',')
              .map(classification => classifications[classification - 1].description)
              .join(', ')}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Quantity
          </Label>
          {entry.quantity}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Unit
          </Label>
          {entry.unit_lks.length ? entry.unit_lks[0].description : ''}
        </Segment>
        {entry.measure !== null ? (
          <Segment>
            <Label color="blue" ribbon>
              Measure
            </Label>
            {entry.measure ? 'Yes' : 'No'}
          </Segment>
        ) : (
          <React.Fragment />
        )}
        {entry.lc &&
          entry.lc_unit && (
            <Segment>
              <Label color="blue" ribbon>
                Liquid Concentrate
              </Label>
              {`${entry.lc} ${entry.lc_unit}`}{' '}
            </Segment>
          )}
        {entry.lc_percent && (
          <Segment>
            <Label color="blue" ribbon>
              Percent Consumed
            </Label>
            {`${entry.lc_percent}%`}
          </Segment>
        )}
        <Segment>
          <Label color="blue" ribbon>
            Reason
          </Label>
          {activityReasons.length &&
            entry.reason_id &&
            entry.reason_id
              .split(',')
              .map(reason => activityReasons[reason - 1].description)
              .join(', ')}
        </Segment>
        {sources.length &&
          entry.source && (
            <Segment>
              <Label color="blue" ribbon>
                Source
              </Label>
              {sources.length && entry.source_rep
                ? `${sources[entry.source - 1].description} - ${entry.source_rep}`
                : sources[entry.source - 1].description}
            </Segment>
          )}
        {'pain_rating_id' in entry &&
          'pain_rating_lks' in entry &&
          entry.pain_rating_id !== null && (
            <Segment>
              <Label color="blue" ribbon>
                Pain at Time of Use
              </Label>
              {entry.pain_rating_lks.length ? `${entry.pain_rating_id} - ${entry.pain_rating_lks[0].description}` : ''}
            </Segment>
          )}
        {'activity_time' in entry && (
          <Segment>
            <Label color="blue" ribbon>
              Time of Use
            </Label>
            {entry.activity_time ? moment(entry.activity_time).format('MM-DD-YYYY hh:mmA') : ''}
          </Segment>
        )}
      </Segment.Group>
    );
    const trigger = (
      <List.Item onClick={this.handleOpen}>
        <List.Content>
          <List.Header>{`${entry.substances[0].name} on ${moment(entry.entry_date).format('MM-DD-YYYY')}`}</List.Header>
        </List.Content>
      </List.Item>
    );

    return !loading ? (
      <Modal open={open} size="tiny" trigger={trigger} dimmer>
        <Modal.Header className="activity-entry-view-header">{header}</Modal.Header>
        <Modal.Content scrolling>{entryView}</Modal.Content>
        {!typicalUpdate && (
          <Modal.Actions className="activity-entry-view-actions">
            {update ? (
              <Button onClick={this.handleUpdate} color="black" icon="edit" labelPosition="right" content="Update" />
            ) : finish ? (
              <Button onClick={this.handleFinish} color="black" icon="edit" labelPosition="right" content="Finish" />
            ) : (
              <AdditionalForm
                text="Use"
                linkBack={`/participant/${participantID}${prev ? '/prev' : ''}`}
                submitForm={this.handleSubmit}
                isPainContent={isPainContent}
              />
            )}
            {!update &&
              !finish && (
                <Button size="tiny" onClick={this.handleEdit} color="orange">
                  {' '}
                  Edit{' '}
                </Button>
              )}
            <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Close" />
          </Modal.Actions>
        )}
        {typicalUpdate && (
          <Modal.Actions className="activity-entry-view-actions">
            {typicalUpdate && (
              <Button size="tiny" onClick={this.handleTypicalRedirect} color="blue">
                {' '}
                Update{' '}
              </Button>
            )}
            <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Close" />
          </Modal.Actions>
        )}
      </Modal>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}
export default withRouter(ActivityEntryView);
ActivityEntryView.propTypes = {
  entry: PropTypes.object,
  header: PropTypes.string,
  title: PropTypes.string,
  participantID: PropTypes.string,
  match: PropTypes.object,
  entryID: PropTypes.number,
  editType: PropTypes.string,
  update: PropTypes.bool,
  finish: PropTypes.bool,
  typicalUpdate: PropTypes.bool,
  classifications: PropTypes.array,
  prev: PropTypes.bool,
};
