import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimePicker from '../UIComponents/TimePicker';

export default class TimeUse extends Component {
  constructor(props) {
    super(props);
    this.stateHandler = this.stateHandler.bind(this);
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { time } = curVal;
    const past = !!time.past;
    stateHandler('time', time.index, { value, index: time.index, valid: true, past });
  }

  render() {
    const { curVal } = this.props;
    return (
      <TimePicker
        curVal={curVal.time.value}
        past={!!curVal.time.past}
        stateHandler={this.stateHandler}
        title="Time of Use"
        subtitle="Enter the time of use"
      />
    );
  }
}

TimeUse.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};
