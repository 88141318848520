import React, { Component } from 'react';
import { Button, Segment, Divider, Header, Label, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter, Link, Redirect } from 'react-router-dom';
import FormHeader from '../UIComponents/FormHeader';
import { fetchWithToken } from '../Util/fetch';
import '../css/Home.css';
import CampLayout from '../UIComponents/CampLayout';

class Home extends Component {
  constructor(props) {
    super(props);
    // Added pain state to see if pain content should be added
    this.state = {
      valid: '',
      activities: [],
      activityDone: false,
      weekly: false,
      recent: false,
      complete: false,
      unfinished: '',
      count: 0,
      loading: false,
      ptID: null,
      pain: null,
    };
  }

  componentDidMount() {
    try {
      const { match, prev } = this.props;
      const hased = match.params.id;
      const decoded = atob(hased);
      const splitData = decoded.split('&');
      const studyID = splitData[1];
      this.setState({ loading: true });

      // Changed url to get participant study
      fetchWithToken()
        .get(`/participant/data/studyID/${studyID}`)
        .then(responsePt => {
          return responsePt.data;
        })
        .then(result => {
          const {id, study} = result;
          axios
            .all([
              this.getVisitID(id, prev),
              this.getWeekly(id),
              this.getRecent(id),
              this.getUnfinished(id),
              this.getCompleted(id, prev),
            ])
            .then(
              axios.spread((visit, weekly, recent, unfinished, complete) => {
                const { activityDone, activities } = visit;
                this.setState({
                  unfinished: unfinished.unfinished,
                  count: unfinished.count,
                  recent,
                  weekly,
                  activityDone,
                  activities,
                  complete,
                  loading: false,
                  ptID: id,
                  // Ask pain content if pt is in CAMP study
                  pain: study === "CAMP"
                });
              })
            );
        });
    } catch (e) {
      this.setState({ valid: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { prev } = this.props;
    const { ptID } = this.state;
    if (prevProps.prev !== prev) {
      axios.all([this.getVisitID(ptID, prev), this.getCompleted(ptID, prev)]).then(
        axios.spread((visit, complete) => {
          const { activityDone, activities } = visit;
          this.setState({
            activityDone,
            activities,
            complete,
            loading: false,
          });
        })
      );
    }
  }

  getVisitID = (id, prev) => {
    const visitIDUrl = `/pt_activity/review/participant/${id}${prev ? '/1' : ''}/`;
    return fetchWithToken()
      .get(visitIDUrl)
      .then(response => {
        const { data } = response;
        if (data.length >= 1) {
          return {
            activityDone: true,
            activities: data,
          };
        }
        return {
          activityDone: false,
          activities: [],
        };
      });
  };

  getWeekly = id => {
    const weeklyUrl = `/participant/weekly/${id}`;
    return fetchWithToken()
      .get(weeklyUrl)
      .then(response => {
        const { weekly } = response.data;
        return weekly;
      });
  };

  getRecent = id => {
    const activitiesUrl = `/participant/recent/${id}`;
    return fetchWithToken()
      .get(activitiesUrl)
      .then(response => {
        const { data } = response;
        if (data.length) {
          if (data[0].pt_activities.length) {
            if (data[0].pt_activities[0].pt_activitysubs.length) {
              return true;
            }
          }
        }
        return false;
      });
  };

  getUnfinished = id => {
    const unfinishedUrl = `/participant/unfinished/${id}/`;
    return fetchWithToken()
      .get(unfinishedUrl)
      .then(response => {
        const { data } = response;
        if (data.length) {
          if (data[0].pt_activities.length) {
            if (data[0].pt_activities[0].pt_activitysubs.length) {
              return { unfinished: true, count: data[0].pt_activities[0].pt_activitysubs.length };
            }
          }
        }
        return { unfinished: false, count: 0 };
      });
  };

  getCompleted = (id, prev) => {
    const completedUrl = `/participant/completed/${id}/${prev ? 1 : 0}`;
    return fetchWithToken()
      .get(completedUrl)
      .then(rep => {
        const { data: completed } = rep;
        return completed;
      });
  };

  render() {
    // Added the pain parameter
    const { activityDone, activities, weekly, recent, complete, unfinished, valid, count, loading, pain } = this.state;
    const { match, prev } = this.props;
    if (valid === false) {
      return <Redirect push to="/notFound" />;
    }

    return !loading ? (
      unfinished !== '' && (
        <CampLayout height="100%">
          <Header as="h1" content="Survey Home" className="home-header" />
          <Segment.Group size="mini">
            <Segment stacked color="blue">
              {complete && !weekly ? (
                <FormHeader
                  type="h3"
                  title={
                    <div>
                      <div>All tasks have been completed.</div>
                      {!prev && <div>Please come back tomorrow.</div>}
                    </div>
                  }
                />
              ) : (
                <FormHeader type="h2" title={prev ? 'Enter Entries for Previous Day' : 'Tasks for Today'} />
              )}
              <Divider horizontal />
              {!prev &&
                weekly && (
                  <Link to={`/participant/${match.params.id}/weekly`}>
                    <Button className="home-buttons" fluid>
                      Complete Weekly Survey
                    </Button>
                  </Link>
                )}
              {!prev && weekly && <Divider horizontal>And</Divider>}
              {activityDone &&
                activities.length &&
                !complete && (
                  // Adding flag to check if pain content should be ignored
                  <Link to={`/participant/${match.params.id}/review${prev ? '/prev' : ''}${!pain ? '?ignorepaincontent=true' : ''}`}>
                    <Button className="home-buttons" fluid>
                      Finish Diary
                    </Button>
                  </Link>
                )}
              {activityDone && !complete && <Divider horizontal>Or</Divider>}
              {!complete && (
                <Link to={`/participant/${match.params.id}/options${prev ? '/prev' : ''}`}>
                  <Button className="home-buttons" fluid>
                    {prev ? 'Enter Activities For Previous Day' : 'Enter Activities For Today'}
                  </Button>
                </Link>
              )}
              {!prev && !complete && <Divider horizontal>Or </Divider>}

              {!prev && (
                  <Link to={`/participant/${match.params.id}/typical/update`}>
                    <Button className="home-buttons" fluid>
                      Update Typical Activities
                    </Button>
                  </Link>
                )}

              {!prev && (
                <div>
                  <Divider horizontal>Or </Divider>
                  <Link to={`/participant/${match.params.id}/prev/`}>
                    <Button className="home-buttons" fluid>
                      Add Entries for Previous Day
                    </Button>
                  </Link>
                </div>
              )}

              {!complete && prev && <Divider horizontal>Or </Divider>}

              {prev && (
                <div>
                  <Link to={`/participant/${match.params.id}`}>
                    <Button className="home-buttons" fluid>
                      Add Entries for Today
                    </Button>
                  </Link>
                </div>
              )}

              {!activityDone && !complete && <Divider horizontal>Or If No Entries</Divider>}
              { // None entry and there is pain content, go to the pain page
                !activityDone &&
                !complete && pain &&(
                  <Link to={`/participant/${match.params.id}/pain${prev ? '/prev' : ''}`}>
                    <Button fluid color="black">
                      None
                    </Button>
                  </Link>
                )}
                { // None entry and there is no pain content, mark activity form for empty submission
                  !activityDone &&
                  !complete && !pain &&(
                    <Link to={`/participant/${match.params.id}/entry${prev ? '/prev' : ''}?fromnone=true`}>
                      <Button fluid color="red">
                        None
                      </Button>
                    </Link>
              )}
              {unfinished && <Divider horizontal />}
              {unfinished && (
                <Link to={`/participant/${match.params.id}/unfinished`}>
                  <Button className="home-buttons" fluid>
                    Finish Entries
                    <Label pointing="left" color="red">
                      {count}
                    </Label>
                  </Button>
                </Link>
              )}
              {recent && <Divider horizontal />}
              {!prev &&
                recent && (
                  <Link to={`/participant/${match.params.id}/recent/edit`}>
                    <Button className="home-buttons" fluid>
                      Edit Past Entries
                    </Button>
                  </Link>
                )}
            </Segment>
          </Segment.Group>
        </CampLayout>
      )
    ) : (
      <CampLayout height="100%">
        <Segment>
          <Loader active inline size="massive">
            Loading
          </Loader>
        </Segment>
      </CampLayout>
    );
  }
}

export default withRouter(Home);

Home.propTypes = {
  match: PropTypes.object,
  prev: PropTypes.bool,
};
