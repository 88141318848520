import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SubmitFormButton from './SubmitFormButton';
import PainTime from '../ActivityComponents/PainTime';
import TimeUse from '../ActivityComponents/TimeUse';

export default class AdditionalForm extends Component {
  constructor(props) {
    super(props);
    this.state = { time: '', pain: '' };
    this.statePainHandler = this.statePainHandler.bind(this);
    this.stateTimeHandler = this.stateTimeHandler.bind(this);
  }

  statePainHandler(form, order, value) {
    this.setState({ pain: value });
  }

  stateTimeHandler(form, order, value) {
    this.setState({ time: moment.utc(value.value).format('YYYY-MM-DD HH:mm:ss') });
  }

  render() {
    // Adding the isPainContent to check if pain confirmation should be displayed
    const { linkBack, submitForm, text, isPainContent } = this.props;
    const { pain, time } = this.state;
    const submit = (
      <SubmitFormButton
        linkBack={linkBack}
        submitForm={submitForm}
        text="Submit"
        dimmerEffect={false}
        values={{ pain: pain.value, time }}
        submit
        closeButton
        active
      />
    );

    const timeModal = (
      <SubmitFormButton
        text="Ok"
        linkBack={linkBack}
        submitForm={submitForm}
        button={submit}
        dimmerEffect={false}
        closeButton
        active
      >
        <TimeUse
          title="Time of Use"
          subtitle="Enter the time of use"
          curVal={{ time: { value: moment().format(), index: 12 } }}
          stateHandler={this.stateTimeHandler}
          closeButton
        />
      </SubmitFormButton>
    );

    const painModal = (
      <SubmitFormButton
        text={text}
        linkBack={linkBack}
        submitForm={submitForm}
        button={timeModal}
        dimmerEffect
        active
        closeButton
      >
        <PainTime
          curVal={{ pain: { value: 0, index: 11 } }}
          title="Pain at time of use?"
          stateHandler={this.statePainHandler}
        />
      </SubmitFormButton>
    );
    
    // If pain content then display pain at use
    if (isPainContent) {
      return painModal;
    }
    return timeModal;
  }
}

AdditionalForm.propTypes = {
  linkBack: PropTypes.string,
  submitForm: PropTypes.func,
  text: PropTypes.string,
};
