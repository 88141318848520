import axios from 'axios';
import base from './endpoints';

const auth = () => {
  const token = sessionStorage.getItem('token');
  return axios.request({
    url: '/auth',
    method: 'get',
    baseURL: base,
    timeout: 8000,
    headers: { 'x-access-token': token },
  });
};

export function adminAuth() {
  const token = sessionStorage.getItem('token');
  return axios.request({
    url: '/auth/admin',
    method: 'get',
    baseURL: base,
    timeout: 8000,
    headers: { 'x-access-token': token },
  });
}

export function ptAuthRoute() {
  const token = sessionStorage.getItem('token');
  const id = sessionStorage.getItem('id');
  return axios.request({
    url: '/auth',
    method: 'post',
    baseURL: base,
    timeout: 8000,
    headers: { 'x-access-token': token },
    data: {
      id,
    },
  });
}

export default auth;
