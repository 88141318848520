import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Segment, Label, Modal, Button, List, Loader } from 'semantic-ui-react';
import FormHeader from '../UIComponents/FormHeader';
import '../css/AdminParticipantEntryView.css';

export default class AdminParticipantNoActivityView extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {}

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    const { date, morning, now, highest, reason, interference, pain, painReasons } = this.props;
    const entryDate = moment(date).format('MM-DD-YYYY hh:mm:ss A');
    const entryView = (
      <Segment.Group>
        <Segment>
          <FormHeader type="h3" title={`Entry on ${entryDate}`} />
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain in the Morning
          </Label>
          {pain.length && morning ?`${morning} - ${pain[morning].description}`: ''}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain Now
          </Label>
          {pain.length && now ? `${now} - ${pain[now].description}` : ''}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Highest Pain
          </Label>
          {pain.length && highest ? `${highest} - ${pain[highest].description}` : ''}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain Reasons
          </Label>
          {painReasons.length && reason ?
            reason
              .split(',')
              .map(choice => painReasons[choice - 1].description)
              .join(', ') : ''}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain Interference
          </Label>
          {pain.length && interference ? `${interference} - ${pain[interference].description}`: ''}
        </Segment>
      </Segment.Group>
    );
    const trigger = (
      <List.Item onClick={this.handleOpen}>
        <List.Content>
          <List.Header>No Activity Entries For {moment(date).format('MM-DD-YYYY')}</List.Header>
        </List.Content>
      </List.Item>
    );

    const { open } = this.state;
    const { studyID } = this.props;

    return pain.length ? (
      <Modal open={open} onClose={this.handleClose} size="tiny" trigger={trigger}>
        <Modal.Header className="participant-entry-view-header">Pain Entry for {studyID}</Modal.Header>
        <Modal.Content scrolling>{entryView}</Modal.Content>
        <Modal.Actions className="participant-entry-view-actions">
          <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Close" />
        </Modal.Actions>
      </Modal>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

AdminParticipantNoActivityView.propTypes = {
  studyID: PropTypes.string,
  morning: PropTypes.number,
  now: PropTypes.number,
  highest: PropTypes.number,
  reason: PropTypes.string,
  interference: PropTypes.number,
  pain: PropTypes.array,
  date: PropTypes.string,
  painReasons: PropTypes.array,
};
