import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ptAuthRoute } from './auth';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = { auth: '' };
  }

  componentDidMount() {
    ptAuthRoute()
      .then(resp => {
        const authed = resp.data.auth;
        this.setState({ auth: authed });
      })
      .catch(() => {
        this.setState({ auth: false });
      });
  }

  render() {
    const { auth } = this.state;
    const { component: Component, ...rest } = this.props;
    return (
      auth !== '' && (
        <Route {...rest} render={() => (auth === true ? <Component {...rest} /> : <Redirect to="/unauthorized" />)} />
      )
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default withRouter(PrivateRoute);
