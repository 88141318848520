import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../UIComponents/MultiSelect';

export default class MeasureForm extends Component {
  constructor(props) {
    super(props);
    this.stateHandler = this.stateHandler.bind(this);
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { measure } = curVal;
    stateHandler('measure', measure.index, { value, index: measure.index, valid: true });
  }

  render() {
    const { curVal } = this.props;
    return (
      <MultiSelect
        formHandler={this.stateHandler}
        title="Did you use a measuring scale to weigh this?"
        options={[{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }]}
        curVal={curVal.measure.value}
        multi={false}
      />
    );
  }
}

MeasureForm.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};
