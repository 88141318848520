import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Segment, Label, Modal, Button, List, Loader } from 'semantic-ui-react';
import FormHeader from '../UIComponents/FormHeader';
import '../css/AdminParticipantEntryView.css';

export default class AdminParticipantPainEntryView extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {}

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    const {
      entry,
      morning,
      now,
      highest,
      reason,
      interference,
      classifications,
      strength,
      pain,
      painReasons,
      activityReasons,
      sources,
    } = this.props;
    const entryDate = moment(entry.entry_date).format('MM-DD-YYYY hh:mm:ss A');
    const entryView = (
      <Segment.Group>
        <Segment>
          <FormHeader type="h3" title={`Entry on ${entryDate}`} />
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Name
          </Label>
          {entry.substances[0].name}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            State
          </Label>
          {entry.substances[0].state_lks[0].description}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Method
          </Label>
          {entry.method_lks[0].description}
        </Segment>
        {entry.device_lks.length !== 0 && (
          <Segment>
            <Label color="blue" ribbon>
              Device
            </Label>
            {entry.device_lks[0].description}
          </Segment>
        )}
        <Segment>
          <Label color="blue" ribbon>
            CBD
          </Label>
          {strength.length && entry.substances[0].cbd_concentrate === null
            ? strength[entry.substances[0].cbd_strength_id - 1].description
            : `${entry.substances[0].cbd_concentrate} ${entry.substances[0].cbd_unit}`}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            THC
          </Label>
          {strength.length && entry.substances[0].thc_concentrate === null
            ? strength[entry.substances[0].thc_strength_id - 1].description
            : `${entry.substances[0].thc_concentrate} ${entry.substances[0].thc_unit}`}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Classification
          </Label>
          {classifications.length &&
            entry.substances[0].classification_id
              .split(',')
              .map(classification => classifications[classification - 1].description)
              .join(', ')}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Quantity
          </Label>
          {entry.quantity}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Unit
          </Label>
          {entry.unit_lks[0].description}
        </Segment>
        {entry.measure !== null ? (
          <Segment>
            <Label color="blue" ribbon>
              Measure
            </Label>
            {entry.measure ? 'Yes' : 'No'}
          </Segment>
        ) : (
          <React.Fragment />
        )}
        <Segment>
          <Label color="blue" ribbon>
            Reason
          </Label>
          {activityReasons.length &&
            entry.reason_id
              .split(',')
              .map(actReason => activityReasons[actReason - 1].description)
              .join(', ')}
        </Segment>
        {sources.length &&
          entry.source && (
            <Segment>
              <Label color="blue" ribbon>
                Source
              </Label>
              {sources.length && entry.source_rep
                ? `${sources[entry.source - 1].description} - ${entry.source_rep}`
                : sources[entry.source - 1].description}
            </Segment>
          )}
        {entry.pain_rating_lks &&
          entry.pain_rating_lks.length && (
            <Segment>
              <Label color="blue" ribbon>
                Pain at time of Use
              </Label>
              {`${entry.pain_rating_id} - ${entry.pain_rating_lks[0].description}`}
            </Segment>
          )}
        <Segment>
          <Label color="blue" ribbon>
            Time of Use
          </Label>
          {moment(entry.activity_time).format('MM-DD-YYYY hh:mm:ss A')}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain in the Morning
          </Label>
          {pain.length && `${morning} - ${pain[morning].description}`}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain Now
          </Label>
          {pain.length && `${now} - ${pain[now].description}`}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Highest Pain
          </Label>
          {pain.length && `${highest} - ${pain[highest].description}`}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain Reasons
          </Label>
          {painReasons.length &&
            reason
              .split(',')
              .map(choice => painReasons[choice - 1].description)
              .join(', ')}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain Interference
          </Label>
          {pain.length && `${interference} - ${pain[interference].description}`}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain Before
          </Label>
          {pain.length && `${entry.pain_before} - ${pain[entry.pain_before].description}`}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Pain After
          </Label>
          {pain.length && `${entry.pain_after} - ${pain[entry.pain_after].description}`}
        </Segment>
      </Segment.Group>
    );
    const trigger = (
      <List.Item onClick={this.handleOpen}>
        <List.Content>
          <List.Header>
            {`${entry.substances[0].name} - ${entry.substances[0].state_lks[0].description}${
              entry.device_lks.length !== 0 ? ` - ${entry.device_lks[0].description}` : ''
            } - ${entryDate}`}
          </List.Header>
        </List.Content>
      </List.Item>
    );

    const { open } = this.state;
    const { studyID } = this.props;

    return pain.length && strength.length && classifications.length ? (
      <Modal open={open} onClose={this.handleClose} size="tiny" trigger={trigger}>
        <Modal.Header className="participant-entry-view-header">Pain Entry for {studyID}</Modal.Header>
        <Modal.Content scrolling>{entryView}</Modal.Content>
        <Modal.Actions className="participant-entry-view-actions">
          <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Close" />
        </Modal.Actions>
      </Modal>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

AdminParticipantPainEntryView.propTypes = {
  entry: PropTypes.object,
  studyID: PropTypes.string,
  morning: PropTypes.number,
  now: PropTypes.number,
  highest: PropTypes.number,
  reason: PropTypes.string,
  interference: PropTypes.number,
  classifications: PropTypes.array,
  strength: PropTypes.array,
  pain: PropTypes.array,
  painReasons: PropTypes.array,
  activityReasons: PropTypes.array,
  sources: PropTypes.array,
};
