import React, { Component } from 'react';
import { Segment, Input, Divider, Button, Dropdown, Label, Popup } from 'semantic-ui-react';
import Tooltip from 'rc-tooltip';
import RCSlider from 'rc-slider';
import update from 'immutability-helper';
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';

const { Handle } = RCSlider;
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip prefixCls="rc-slider-tooltip" overlay={value} visible={dragging} placement="top" key={index}>
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};
export default class ConcentrationSlider extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = {
      value: {
        rating: curVal.rating !== null ? curVal.rating : 1,
        rep: curVal.rep,
        unit: curVal.unit,
        valid: curVal.valid,
      },
      unitErr: false,
      inputErr: false,
      updated: false,
      isOpen: false,
      updatePopUknown: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAfterChange = this.handleAfterChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleUnknow = this.handleUnknow.bind(this);
  }

  handleChange(value) {
    const newState = update(this.state, {
      value: { rating: { $set: value } },
    });

    this.setState(newState);
  }

  handleInputChange(event) {
    const { value } = event.target;
    const newState = update(this.state, {
      value: { rep: { $set: value } },
    });

    this.setState(newState);
  }

  handleOnClick() {
    const { value } = this.state;
    const { stateHandler } = this.props;
    if (!value.unit && !value.rep) {
      const newState = update(this.state, {
        inputErr: { $set: true },
        unitErr: { $set: true },
      });

      this.setState(newState);
    } else if (!value.unit && value.rep) {
      const newState = update(this.state, {
        unitErr: { $set: true },
        inputErr: { $set: false },
      });

      this.setState(newState);
    } else if (!value.rep && value.unit) {
      const newState = update(this.state, {
        inputErr: { $set: true },
        unitErr: { $set: false },
      });

      this.setState(newState);
    } else {
      const { edit } = this.props;
      const newState = update(this.state, {
        inputErr: { $set: false },
        unitErr: { $set: false },
        updated: { $set: edit },
        isOpen: { $set: true },
      });

      this.setState(newState);
      stateHandler({
        rating: value.rating,
        rep: !Number.isNaN(parseFloat(value.rep)) ? parseFloat(value.rep) : null,
        unit: value.unit,
        valid: true,
      });
    }
  }

  handleAfterChange() {
    const { value } = this.state;
    const { stateHandler } = this.props;
    stateHandler({
      rating: value.rating,
      rep: !Number.isNaN(parseFloat(value.rep)) ? parseFloat(value.rep) : null,
      unit: value.unit,
      valid: true,
    });
  }

  handleSelection(event, value) {
    const newState = update(this.state, {
      value: { unit: { $set: value.value } },
    });

    this.setState(newState);
  }

  handleUnknow() {
    const { stateHandler } = this.props;
    const newState = update(this.state, {
      value: { unit: { $set: null }, rep: { $set: null }, valid: { $set: true }, rating: { $set: 1 } },
      updatePopUknown: { $set: true },
    });
    this.setState(newState);
    stateHandler({
      rating: null,
      rep: 999,
      unit: null,
      valid: true,
    });
  }

  render() {
    const { inputErr, unitErr, value, updated, isOpen, updatePopUknown } = this.state;
    const { title, text, curVal, edit, marks } = this.props;
    if (edit && (updated === true || updatePopUknown === true)) {
      setTimeout(() => {
        this.setState({ updated: false, isOpen: false, updatePopUknown: false });
      }, 1000);
    }
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title={title} />
          <Segment.Group raised stacked>
            <Segment color="blue">
              <FormHeader type="h4" color="blue" title={text} />
            </Segment>
            <Segment.Group stacked color="blue">
              <Segment>
                {inputErr && (
                  <Label size="tiny" basic color="red" pointing="below">
                    Please enter an amount
                  </Label>
                )}
                <Input
                  pattern="\d+(\.\d*)?"
                  type="number"
                  placeholder="Type in amount"
                  onChange={this.handleInputChange}
                  defaultValue={curVal.rep}
                />
              </Segment>
              <Segment vertical>
                {unitErr && (
                  <Label size="tiny" basic color="red" pointing="right">
                    Please select a unit
                  </Label>
                )}
                <Dropdown
                  placeholder="unit"
                  selection
                  button
                  compact
                  text={value.unit ? value.unit : 'unit'}
                  value={value.unit}
                  onChange={this.handleSelection}
                  options={[
                    {
                      key: 1,
                      text: 'mg',
                      value: 'mg',
                    },
                    {
                      key: 2,
                      text: 'g',
                      value: 'g',
                    },
                    {
                      key: 3,
                      text: '%',
                      value: '%',
                    },
                  ]}
                />
              </Segment>
              <Segment vertical>
                {edit ? (
                  <Popup
                    trigger={<Button content="Update" basic color="blue" icon="right arrow" labelPosition="right" />}
                    content={<Label color="green">Updated</Label>}
                    on="click"
                    open={isOpen}
                    onOpen={this.handleOnClick}
                    position="right center"
                  />
                ) : (
                  <Button
                    onClick={this.handleOnClick}
                    content="Next"
                    basic
                    color="blue"
                    icon="right arrow"
                    labelPosition="right"
                  />
                )}
              </Segment>
            </Segment.Group>
            <Divider horizontal>Or</Divider>
            <Segment raised padded="very" color="blue">
              <RCSlider
                min={1}
                max={5}
                marks={marks}
                defaultValue={curVal.rating}
                value={value.rating}
                handle={handle}
                onChange={this.handleChange}
                onAfterChange={this.handleAfterChange}
                trackStyle={{ height: 7 }}
                handleStyle={{
                  height: 20,
                  width: 20,
                  top: 5,
                  marginLeft: -10,
                }}
                railStyle={{ height: 7 }}
              />
            </Segment>
            <Divider horizontal>Or</Divider>
            <Segment>
              {edit ? (
                <Popup
                  trigger={<Button content="Unknown" basic color="blue" />}
                  content={<Label color="green">Updated</Label>}
                  on="click"
                  open={updatePopUknown}
                  onOpen={this.handleUnknow}
                  position="right center"
                />
              ) : (
                <Button onClick={this.handleUnknow} content="Unknown" basic color="blue" />
              )}
            </Segment>
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

ConcentrationSlider.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  marks: PropTypes.object,
  edit: PropTypes.bool,
};

handle.propTypes = {
  value: PropTypes.number,
  dragging: PropTypes.bool,
  index: PropTypes.any,
};
