import React, { Component } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class ParticipantActivation extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { participantID, studyID, activationHandler, triggerText, confirmationText, deactivate } = this.props;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button
            onClick={() => this.setState({ open: true })}
            basic
            color={deactivate === true ? 'red' : 'blue'}
            fluid
          >
            {' '}
            {triggerText}
          </Button>
        }
        open={open}
        closeIcon
      >
        <Modal.Header className="submit-confirmantion-header">Confirmation</Modal.Header>
        <Modal.Content>{<h3 style={{ textAlign: 'center' }}>{`${confirmationText} ${studyID}`}</h3>}</Modal.Content>
        <Modal.Actions className="submit-from-modal-actions">
          <Button color="red" onClick={() => this.setState({ open: false })}>
            <Icon name="remove" /> No
          </Button>
          <Button color="green" onClick={() => activationHandler(participantID)}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ParticipantActivation.propTypes = {
  participantID: PropTypes.number,
  studyID: PropTypes.string,
  activationHandler: PropTypes.func,
  triggerText: PropTypes.string,
  confirmationText: PropTypes.string,
  deactivate: PropTypes.bool,
};
