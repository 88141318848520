import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SliderQuestions from '../UIComponents/SliderQuestions';

export default class BeforeActivity extends Component {
  constructor(props) {
    super(props);
    this.stateHandler = this.stateHandler.bind(this);
  }

  stateHandler(value) {
    const { stateHandler } = this.props;
    stateHandler('before', '0', value);
  }

  render() {
    const { curVal } = this.props;
    const marks = {
      0: (
        <strong>
          0<br />
          No
        </strong>
      ),
      1: <strong>1</strong>,
      2: <strong>2</strong>,
      3: <strong>3</strong>,
      4: <strong>4</strong>,
      5: (
        <strong>
          5<br />
          Avg
        </strong>
      ),
      6: <strong>6</strong>,
      7: <strong>7</strong>,
      8: <strong>8</strong>,
      9: <strong>9</strong>,
      10: (
        <strong>
          10
          <br />
          Worst
        </strong>
      ),
    };
    return (
      <SliderQuestions
        marks={marks}
        curVal={curVal.before}
        stateHandler={this.stateHandler}
        minVal
        title="What was your pain 60 min before this activity?"
      />
    );
  }
}

BeforeActivity.propTypes = {
  stateHandler: PropTypes.func,
  curVal: PropTypes.any,
};
