import React, { Component } from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DropdownSearch from '../UIComponents/DropdownSearch';
import { fetchWithToken } from '../Util/fetch';

export default class NameSearch extends Component {
  constructor(props) {
    super(props);
    this.stateHandler = this.stateHandler.bind(this);
    this.state = { options: [], loading: true };
  }

  componentDidMount() {
    const { participantID } = this.props;
    fetchWithToken()
      .get('/state')
      .then(
        response => {
          const states = response.data;
          fetchWithToken()
            .get(`/substance/participant/${participantID}`)
            .then(responsePt => {
              const filteredData = _.uniqBy(responsePt.data, 'name').filter(option => option.state_id !== null);
              console.log(filteredData);
              const data = filteredData.map(option => ({
                key: option.substance_id,
                text: `${option.name} - ${states[Number(option.state_id - 1)].description}`,
                value: option.name,
                state: states[Number(option.state_id - 1)].description,
              }));
              this.setState({ options: data, loading: false });
            });
        },
        () => {}
      );
  }

  stateHandler(value, addon) {
    const { curVal, stateHandler } = this.props;
    const { name } = curVal;
    stateHandler('name', name.index, { value, addon, index: name.index, valid: true });
  }

  render() {
    const { options, loading } = this.state;
    const { curVal } = this.props;
    const customSearch = (option, query) => {
      const re = new RegExp(_.escapeRegExp(_.lowerCase(query)));
      const filtertedNames = options.filter(opt => re.test(_.lowerCase(opt.value)));
      const stateNames = options.filter(opt => re.test(_.lowerCase(opt.state)));
      const filtered = _.union(filtertedNames, stateNames);
      return filtered;
    };
    return !loading ? (
      <DropdownSearch
        title="Enter Strain Name"
        text="Search for Strain"
        addon
        parentHandler={this.stateHandler}
        optionData={options}
        curVal={curVal.name.value}
        customSearch={customSearch}
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

NameSearch.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
  participantID: PropTypes.number,
};
