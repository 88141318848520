import React, { Component } from 'react';
import { Segment, Label, Modal, Button, List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter, Redirect } from 'react-router-dom';
import FormHeader from '../UIComponents/FormHeader';
import '../css/AdminParticipantEntryView.css';

class AdminParticipantEntryView extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, update: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.hadnleUpdate = this.hadnleUpdate.bind(this);
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  hadnleUpdate() {
    this.setState({ update: true });
  }

  render() {
    const { entry, classifications, strength, type, activityReasons, sources } = this.props;
    const { update } = this.state;
    const subID = entry.substance_id;
    const entryID = entry.id;
    const participantID = entry.participant_id;
    if (update) {
      return (
        <Redirect
          push
          to={{
            pathname: `/admin/update/typical/${entryID}`,
            state: { subID, participantID },
          }}
        />
      );
    }
    const subs_name = entry.substances[0].name ? entry.substances[0].name : "\"None\"";
    const subs_state = entry.substances[0].state_lks.length && entry.substances[0].state_lks[0].description ?
      entry.substances[0].state_lks[0].description : "";
    const method = entry.method_lks.length && entry.method_lks[0].description ?
        entry.method_lks[0].description : "";
    const subs_strength = strength.length && entry.substances[0].cbd_strength_id ?
      strength[entry.substances[0].cbd_strength_id - 1].description : "";
    const unit = entry.unit_lks.length && entry.unit_lks[0].description ?
      entry.unit_lks[0].description : ""

    const entryDate = moment(entry.entry_date).format('MM-DD-YYYY hh:mm:ss A');
    const entryView = (
      <Segment.Group>
        <Segment>
          <FormHeader type="h3" title={`Entry on ${entryDate}`} />
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Name
          </Label>
          {subs_name}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            State
          </Label>
          {subs_state}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Method
          </Label>
          {method}
        </Segment>
        {entry.device_lks.length !== 0 && (
          <Segment>
            <Label color="blue" ribbon>
              Device
            </Label>
            {entry.device_lks[0].description}
          </Segment>
        )}
        <Segment>
          <Label color="blue" ribbon>
            CBD
          </Label>
          {entry.substances[0].cbd_concentrate !== null
            ? entry.substances[0].cbd_concentrate === 999
              ? 'Unknown'
              : `${entry.substances[0].cbd_concentrate} ${entry.substances[0].cbd_unit}`
            : subs_strength}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            THC
          </Label>
          {entry.substances[0].thc_concentrate !== null
            ? entry.substances[0].thc_concentrate === 999
              ? 'Unknown'
              : `${entry.substances[0].thc_concentrate} ${entry.substances[0].thc_unit}`
            : subs_strength}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Classification
          </Label>
          {classifications.length &&
            entry.substances[0].classification_id !== '' &&
            entry.substances[0].classification_id
              .split(',')
              .map(classification => classifications[classification - 1].description)
              .join(', ')}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Quantity
          </Label>
          {entry.quantity}
        </Segment>
        {entry.measure !== null ? (
          <Segment>
            <Label color="blue" ribbon>
              Measure
            </Label>
            {entry.measure ? 'Yes' : 'No'}
          </Segment>
        ) : (
          <React.Fragment />
        )}
        {entry.lc &&
          entry.lc_unit && (
            <Segment>
              <Label color="blue" ribbon>
                Liquid Conentrate
              </Label>
              {`${entry.lc} ${entry.lc_unit}`}
            </Segment>
          )}
        {entry.lc_percent && (
          <Segment>
            <Label color="blue" ribbon>
              Percent Consumed
            </Label>
            {`${entry.lc_percent}%`}
          </Segment>
        )}
        <Segment>
          <Label color="blue" ribbon>
            Unit
          </Label>
          {unit}
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Reason
          </Label>
          {activityReasons.length &&
            entry.reason_id &&
            entry.reason_id
              .split(',')
              .map(reason => activityReasons[reason - 1].description)
              .join(', ')}
        </Segment>
        {sources.length &&
          entry.source && (
            <Segment>
              <Label color="blue" ribbon>
                Source
              </Label>
              {sources.length && entry.source_rep
                ? `${sources[entry.source - 1].description} - ${entry.source_rep}`
                : sources[entry.source - 1].description}
            </Segment>
          )}
        {entry.pain_rating_id !== null &&
          entry.pain_rating_lks && (
            <Segment>
              <Label color="blue" ribbon>
                Pain at Time of Use
              </Label>
              {`${entry.pain_rating_id} - ${entry.pain_rating_lks[0].description}`}
            </Segment>
          )}
        {entry.activity_time && (
          <Segment>
            <Label color="blue" ribbon>
              Time of Use
            </Label>
            {moment(entry.activity_time).format('MM-DD-YYYY hh:mm:ss A')}
          </Segment>
        )}
      </Segment.Group>
    );
    const trigger = (
      <List.Item onClick={this.handleOpen}>
        <List.Content>
          <List.Header>
            {`${subs_name} - ${subs_state}${
              entry.device_lks.length !== 0 ? ` - ${entry.device_lks[0].description}` : ''
            } ${type === 'typical' ? '' : `-${entryDate}`}`}
          </List.Header>
        </List.Content>
      </List.Item>
    );

    const { open } = this.state;
    const { studyID } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose} size="tiny" trigger={trigger}>
        <Modal.Header className="participant-entry-view-header">Entry for {studyID}</Modal.Header>
        <Modal.Content scrolling>{entryView}</Modal.Content>
        <Modal.Actions className="participant-entry-view-actions">
          <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Close" />
          {type === 'typical' && (
            <Button onClick={this.hadnleUpdate} color="black" icon="edit" labelPosition="right" content="Update" />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

AdminParticipantEntryView.propTypes = {
  entry: PropTypes.object,
  studyID: PropTypes.string,
  classifications: PropTypes.array,
  strength: PropTypes.array,
  type: PropTypes.string,
  activityReasons: PropTypes.array,
  sources: PropTypes.array,
};

export default withRouter(AdminParticipantEntryView);
