import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import { fetchWithToken } from '../Util/fetch';
import CheckOptions from '../UIComponents/CheckOptions';

export default class ClassificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = { classifications: [], loading: true };
    this.stateHandler = this.stateHandler.bind(this);
  }

  async componentDidMount() {
    const { data: unformattedClassifications } = await fetchWithToken().get('/classification');
    const classifications = unformattedClassifications.map(classification => ({
      id: classification.classification_id,
      name: classification.description,
    }));

    this.setState({ classifications, loading: false });
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { classification } = curVal;
    const edit = !!classification.edit;
    stateHandler('classification', classification.index, { value, index: classification.index, edit, valid: true });
  }

  render() {
    const { classifications, loading } = this.state;
    const { curVal } = this.props;
    return !loading ? (
      <CheckOptions
        formHandler={this.stateHandler}
        title={
          <React.Fragment>
            <div>Classification</div>
            <div>(Please mark all that apply)</div>
          </React.Fragment>
        }
        options={classifications}
        curVal={curVal.classification.value}
        edit={!!curVal.classification.edit}
        none
        classification
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

ClassificationForm.propTypes = {
  stateHandler: PropTypes.func,
  curVal: PropTypes.any,
};
