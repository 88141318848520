import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Loader, Segment, Menu, List } from 'semantic-ui-react';
import moment from 'moment';
import { fetchWithToken } from '../Util/fetch';
import ActivityList from '../UIComponents/ActivityList';
import ActivityEntryView from '../UIComponents/ActivityEntryView';

class EditRecentActivities extends Component {
  constructor(props) {
    super(props);
    this.state = { activities: [], classifications: [] };
  }

  componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const splitData = decoded.split('&');
    const studyID = splitData[1];

    fetchWithToken()
      .get(`/participant/id/studyID/${studyID}`)
      .then(repsonse => {
        const id = repsonse.data;
        const activitiesUrl = `/participant/recent/${id}`;
        fetchWithToken()
          .get(activitiesUrl)
          .then(response => {
            const { data } = response;
            if (data.length) {
              const activities = data;
              this.setState({ activities });
            }
          })
          .then(() => {
            fetchWithToken()
              .get('/classification')
              .then(classficaitionsRep => {
                const { data } = classficaitionsRep;
                const classifications = data;
                this.setState({ classifications });
              });
          });
      });
  }

  render() {
    const { activities, classifications } = this.state;
    const { match } = this.props;

    let activityList = [];
    let dates = [];
    if (activities.length) {
      dates = activities[0].pt_activities.map(activity => moment(activity.entry_date).format('YYYY-MM-DD'));
      activityList = activities[0].pt_activities.map(activity =>
        activity.pt_activitysubs.map(ptSubs => (
          <List.Item key={`recent-edit${ptSubs.id}`}>
            <List.Header>
              <ActivityEntryView
                entry={ptSubs}
                header={ptSubs.substances[0].name}
                title={`Entered on ${moment(ptSubs.entry_date).format('MM-DD-YYYY hh:mmA')}`}
                participantID={match.params.id}
                entryID={ptSubs.id}
                editType="past"
                update
                classifications={classifications}
              />
            </List.Header>
          </List.Item>
        ))
      );
    }
    return activities.length ? (
      <ActivityList activities={activityList} title="Edit Past Entries" blocked dates={dates}>
        <Menu fixed="top" color="blue" widths={1}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}`} content="Home" />
        </Menu>
      </ActivityList>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(EditRecentActivities);

EditRecentActivities.propTypes = {
  match: PropTypes.object,
};
