import React, { Component } from 'react';
import { Menu, Segment, Loader } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import NameSearch from '../ActivityComponents/nameSearch';
import StateForm from '../ActivityComponents/StateFrom';
import MethodForm from '../ActivityComponents/MethodForm';
import CBDForm from '../ActivityComponents/CBDForm';
import THCForm from '../ActivityComponents/THCForm';
import ClassificationForm from '../ActivityComponents/ClassificationForm';
import QuantityForm from '../ActivityComponents/QuantityFrom';
import ReasonForm from '../ActivityComponents/ReasonForm';
import ActivityEntryForm from '../ActivityComponents/ActivityEntryForm';
import TimeUse from '../ActivityComponents/TimeUse';
import PainTime from '../ActivityComponents/PainTime';
import MeasureForm from '../ActivityComponents/MeasureForm';
import ProductSourceForm from '../ActivityComponents/ProductSourceForm';
import LiquidForm from '../ActivityComponents/LiquidForm';
import { fetchWithToken } from '../Util/fetch';
import { ptSubAdd } from '../Util/helper';

const components = [
  {
    comp: NameSearch,
    active: true,
    text: 'NameSearch',
    name: 'name',
  },
  {
    comp: StateForm,
    active: true,
    text: 'StateForm',
    name: 'state',
  },
  {
    comp: MethodForm,
    active: true,
    text: 'MethodForm',
    name: 'method',
  },
  {
    comp: CBDForm,
    active: true,
    text: 'CBDForm',
    name: 'CBD',
  },
  {
    comp: THCForm,
    active: true,
    text: 'THCForm',
    name: 'THC',
  },
  {
    comp: ClassificationForm,
    active: true,
    text: 'ClassificationForm',
    name: 'classification',
  },
  {
    comp: QuantityForm,
    active: true,
    text: 'QuantityForm',
    name: 'quantity',
  },
  {
    comp: MeasureForm,
    active: true,
    text: 'MeasureForm',
    name: 'measure',
  },
  {
    comp: LiquidForm,
    active: true,
    text: 'LiquidForm',
    name: 'lc',
  },
  {
    comp: ReasonForm,
    active: true,
    text: 'ReasonForm',
    name: 'reason',
  },
  {
    comp: ProductSourceForm,
    active: true,
    text: 'ProductSourceForm',
    name: 'source',
  },
  {
    comp: PainTime,
    active: true,
    text: 'PainTime',
    name: 'pain',
  },
  {
    comp: TimeUse,
    active: true,
    text: 'TimeUse',
    name: 'time',
  },
];

const initState = {
  form: 'name',
  value: '',
  finished: true,
  components,
  index: 11,
  height: '',
};

class EditForm extends Component {
  constructor(props) {
    super(props);
    // Adding the isPainContent to check if pain question should be displayed
    this.state = { id: '', initValues: null, form: null, isPainContent: true };
    this.submitHandler = this.submitHandler.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { match, location } = this.props;
    const { entryID } = match.params;

    let editTypeVal = 'recent';
    if (location.state) {
      editTypeVal = location.state.editType;
    }

    let url = `/pt_activitysub/id/${entryID}`;

    if (editTypeVal === 'typical') {
      url = `/pt_profile/id/${entryID}`;
    }

    fetchWithToken()
      .get(url)
      .then(response => {
        const data = response.data[0];
        const substance = data.substances[0];
        const initValues = {
          name: {
            value: substance.name,
            addon: false,
            index: 0,
            valid: true,
          },
          state: {
            index: 1,
            value: substance.state_id,
          },
          method: {
            method: data.method_id,
            device: data.device_id,
            index: 2,
            valid: true,
          },
          CBD: {
            rating: substance.cbd_strength_id,
            rep: substance.cbd_concentrate,
            unit: substance.cbd_unit,
            index: 3,
            edit: true,
            valid: true,
          },
          THC: {
            rating: substance.thc_strength_id,
            rep: substance.thc_concentrate,
            unit: substance.thc_unit,
            index: 4,
            edit: true,
            valid: true,
          },
          classification: {
            index: 5,
            value: substance.classification_id ? substance.classification_id.split(',').map(id => Number(id)) : [],
            edit: true,
            valid: true,
          },
          quantity: {
            value: data.quantity !== null ? data.quantity : null,
            unit: data.unit_id,
            index: 6,
            valid: true,
          },
          measure: {
            index: 7,
            value: data.measure !== null ? data.measure : null,
            valid: true,
          },
          lc: {
            index: 8,
            value: data.lc !== null ? data.lc : null,
            unit: data.lc_unit !== null ? data.lc_unit : null,
            percent: data.percent !== null ? data.lc_percent : null,
            valid: true,
          },
          reason: {
            index: 9,
            value: data.reason_id ? data.reason_id.split(',').map(id => Number(id)) : [],
            edit: true,
            valid: true,
          },
          source: {
            value: data.source !== null ? data.source : null,
            rep: data.source_rep !== null ? data.source_rep : null,
            index: 10,
            valid: true,
          },
          pain: {
            value: data.pain_rating_id !== null ? data.pain_rating_id : 0,
            index: 11,
            valid: true,
          },
          time: {
            value: data.activity_time,
            index: 12,
            valid: true,
            past: !!(!!location.state && location.state.editType === 'past'),
          },
        };
        const form = data.form_id;
        const hased = match.params.id;
        const decoded = atob(hased);
        const splitData = decoded.split('&');
        const studyID = splitData[1];
        // Updated fetch url to get pt study and update isPainContent
        fetchWithToken()
          .get(`/participant/data/studyID/${studyID}`)
          .then(responseID => {
            const { id, study } = responseID.data;
            this.setState({ id, initValues, form, isPainContent: study === "CAMP" });
          });
      });
  }

  submitHandler(values) {
    const { id } = this.state;
    const { prev } = this.props;
    const activityTime = moment
      .utc()
      .subtract(prev ? 1 : 0, 'days')
      .format('YYYY-MM-DD HH:mm:ss');
    const activityDate = moment
      .utc()
      .subtract(prev ? 1 : 0, 'days')
      .format('YYYY-MM-DD');
    const subtanceURL = '/substance/add';
    const ptSubUrl = '/pt_activitysub/add';
    const ptActUrl = 'pt_activity/add';
    const visitIDUrl = `/pt_activity/visit_id/participant/${id}${prev ? '/1' : ''}`;
    const substanceFields = {
      name: values.name.value,
      state_id: values.state.value,
      thc_concentrate: values.THC.rep,
      thc_unit: values.THC.unit,
      cbd_concentrate: values.CBD.rep,
      cbd_unit: values.CBD.unit,
      thc_strength_id: values.THC.rep !== null ? null : values.THC.rating,
      cbd_strength_id: values.CBD.rep !== null ? null : values.CBD.rating,
      is_active: 1,
      is_study_approved: 0,
      classification_id: values.classification.value.join(),
      entry_date: activityTime,
    };

    fetchWithToken()
      .post(subtanceURL, {
        fields: substanceFields,
      })
      .then(response => {
        const substanceID = response.data.id;
        const { addon } = values.name;
        const ptActivtyFields = {
          participant_id: id,
          activity_date: activityTime,
          status_id: 1,
          entry_date: activityTime,
          date: activityDate,
        };
        const ptActivtySubFields = {
          activity_time: moment
            .utc(values.time.value)
            .subtract(prev ? 1 : 0, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
          participant_id: id,
          substance_id: substanceID,
          method_id: values.method.method,
          device_id: values.method.device ? values.method.device : null,
          quantity: values.quantity.value,
          unit_id: values.quantity.unit,
          reason_id: values.reason.value.join(),
          pain_rating_id: values.pain.value,
          measure: values.measure.value,
          is_active: 1,
          entry_date: activityTime,
          source: values.source.value,
          source_rep: values.source.rep,
          status_id: 2,
          form_id: 12,
        };

        fetchWithToken()
          .get(visitIDUrl)
          .then(responseVisit => {
            const { data } = responseVisit;
            if (data.length === 1) {
              const visitID = data[0].visit_id;
              ptActivtySubFields.visit_id = visitID;
              fetchWithToken().post(ptSubUrl, {
                fields: ptActivtySubFields,
              });
            } else {
              fetchWithToken()
                .post(ptActUrl, {
                  fields: ptActivtyFields,
                })
                .then(responseAct => {
                  const visitID = responseAct.data.visit_id;
                  ptActivtySubFields.visit_id = visitID;
                  fetchWithToken().post(ptSubUrl, {
                    fields: ptActivtySubFields,
                  });
                });
            }
          });

        if (addon) {
          ptSubAdd(substanceID, id);
        }
      });
  }

  handleUpdate(values) {
    const { location, match, prev } = this.props;
    const { id } = this.state;

    const { entryID } = match.params;
    let subtanceID;
    if (location.state && location.state.subID) {
      subtanceID = location.state.subID;
    }
    const subtanceURL = `/substance/update/${subtanceID}`;
    const ptSubUrl = `/pt_activitysub/update/id/${entryID}`;
    const { addon } = values.name;
    const filterClassification = values.classification.value.filter(classID => classID !== 0);
    const updateSub = () => {
      const substanceFields = {
        name: values.name.value,
        state_id: values.state.value,
        thc_concentrate: values.THC.rep,
        thc_unit: values.THC.unit,
        cbd_concentrate: values.CBD.rep,
        cbd_unit: values.CBD.unit,
        thc_strength_id: values.THC.rating,
        cbd_strength_id: values.CBD.rating,
        classification_id: filterClassification.join(),
      };
      return fetchWithToken().post(subtanceURL, {
        fields: substanceFields,
      });
    };

    const updatePtActSub = () => {
      const ptActivtySubFields = {
        activity_time: moment
          .utc(values.time.value)
          .subtract(prev ? 1 : 0, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        method_id: values.method.method,
        device_id: values.method.device ? values.method.device : null,
        quantity: values.quantity.value,
        unit_id: values.quantity.unit,
        reason_id: values.reason.value.join(),
        pain_rating_id: values.pain.value,
        measure: values.measure.value,
        source: values.source.value,
        source_rep: values.source.rep,
        lc: values.lc.value,
        lc_unit: values.lc.unit,
        lc_percent: values.lc.percent,
        status_id: 2,
        form_id: 12,
      };
      return fetchWithToken().post(ptSubUrl, {
        values: ptActivtySubFields,
      });
    };

    axios.all([updatePtActSub(), updateSub()]);

    if (addon) {
      ptSubAdd(subtanceID, id);
    }
  }

  render() {
    // Adding the isPainContent to check if pain question should be displayed
    const { initValues, form, id, isPainContent } = this.state;
    const { match, history, location, prev } = this.props;
    initState.values = initValues;
    if (form) {
      const updatedComps = components.map((comp, i) => ({
        comp: comp.comp,
        // If there is no pain content, then don't display pain question
        active: i <= form && (isPainContent || i !== 11),
        text: comp.text,
        name: comp.name,
      }));
      initState.components = updatedComps;
      if (form !== 12) {
        initState.index = form;
        initState.finished = false;
        initState.form = updatedComps[form].name;
      } else {
        initState.finished = true;
      }
    } else {
      const updatedComps = components.map(comp => ({
        comp: comp.comp,
        active: true,
        text: comp.text,
        name: comp.name,
      }));
      initState.components = updatedComps;
      initState.finished = true;
    }

    let updateVal = false;
    if (location.state && location.state.update) {
      updateVal = location.state.update;
    }
    return initValues ? (
      <ActivityEntryForm
        initialState={initState}
        endForm="time"
        titleName="Edit Activity Entry"
        height="5200px"
        linkBack="/participant"
        participantID={id}
        submitHandler={updateVal ? this.handleUpdate : this.submitHandler}
        prev={prev}
        pain={isPainContent}
      >
        <Menu fixed="top" color="blue" widths={updateVal === true ? 2 : 3}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}${prev ? '/prev' : ''}`} content="Home" />
          {updateVal && <Menu.Item onClick={() => history.goBack()} content="Go Back" />}
          {!updateVal && (
              <Menu.Item
                as={Link}
                to={`/participant/${match.params.id}/recent${prev ? '/prev' : ''}`}
                content="Recent"
              />
            ) && (
              <Menu.Item
                as={Link}
                to={`/participant/${match.params.id}/typical${prev ? '/prev' : ''}`}
                content="Typical"
              />
            )}
        </Menu>
      </ActivityEntryForm>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(EditForm);

EditForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object,
  history: PropTypes.any,
  prev: PropTypes.bool,
};
