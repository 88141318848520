import moment from 'moment';
import React from 'react';
import { Modal, Icon, Button } from 'semantic-ui-react';
import { fetchWithToken } from './fetch';

export function ptSubAdd(substanceID, participantID) {
  const ptSubUrl = '/pt_substance/add';
  const ptSubFields = {
    participant_id: participantID,
    substance_id: substanceID,
    is_active: 1,
    entry_date: moment().format('YYYY-MM-DD'),
  };

  fetchWithToken().post(ptSubUrl, {
    fields: ptSubFields,
  });
}

export const checkValues = answers => {
  const { source, method } = answers;
  return source.valid && method.valid;
};

export const error = (errorOpen, handler) => (
  <Modal open={errorOpen} closeIcon>
    <Modal.Header className="submit-confirmantion-header">Error</Modal.Header>
    <Modal.Content>{<h3 style={{ textAlign: 'center' }}>Please Enter a value for all fields</h3>}</Modal.Content>
    <Modal.Actions className="submit-from-modal-actions">
      <Button color="green" onClick={() => handler()}>
        <Icon name="checkmark" /> No
      </Button>
    </Modal.Actions>
  </Modal>
);
