import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../css/SumbitFormButton.css';

export default class SubmitFormButton extends Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    const { submit, submitForm, values } = this.props;
    if (submit) {
      submitForm(values);
    }
    this.setState({ modalOpen: true });
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  render() {
    const { text, dimmerEffect, children, button, linkBack, closeButton, active, floatPos } = this.props;
    const { modalOpen } = this.state;
    const trigger = (
      <Button disabled={!active} floated={floatPos ? floatPos : "right"} color="green" onClick={this.handleOpen}>
        {text}
      </Button>
    );
    const moodelContent = (
      <React.Fragment>
        <Modal.Header className="submit-confirmantion-header">Confirmation</Modal.Header>
        <Modal.Content>{children || <h3 style={{ textAlign: 'center' }}>Entry was submitted</h3>}</Modal.Content>
        <Modal.Actions className="submit-from-modal-actions">
          <Modal.Header />
          {button || (
            <Link to={linkBack}>
              <Button
                style={{ textAlign: 'center' }}
                size="small"
                onClick={this.handleClose}
                positive
                icon="checkmark"
                labelPosition="right"
                content="OK"
              />
            </Link>
          )}
          {closeButton && (
            <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Cancel" />
          )}
        </Modal.Actions>
      </React.Fragment>
    );
    return dimmerEffect ? (
      <Modal trigger={trigger} open={modalOpen} onClose={this.handleClose} size="mini" dimmer={dimmerEffect}>
        {moodelContent}
      </Modal>
    ) : (
      <Modal trigger={trigger} open={modalOpen} onClose={this.handleClose} size="mini">
        {moodelContent}
      </Modal>
    );
  }
}

SubmitFormButton.propTypes = {
  linkBack: PropTypes.string,
  submitForm: PropTypes.func,
  text: PropTypes.string,
  children: PropTypes.any,
  button: PropTypes.object,
  dimmerEffect: PropTypes.bool,
  values: PropTypes.object,
  submit: PropTypes.bool,
  closeButton: PropTypes.bool,
  active: PropTypes.bool,
};
