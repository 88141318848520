import React, { Component } from 'react';
import { Segment, Input, Divider, Button, Dropdown, Label, Loader } from 'semantic-ui-react';
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import FormHeader from '../UIComponents/FormHeader';
import { fetchWithToken } from '../Util/fetch';

class PercentForm extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = { loading: true, unitErr: false, inputErr: false, units: [], ...curVal.lc };
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handlePercent = this.handlePercent.bind(this);
    this.handleUnknowAmount = this.handleUnknowAmount.bind(this);
    this.handleUnknowPercent = this.handleUnknowPercent.bind(this);
  }

  componentDidMount() {
    fetchWithToken()
      .get('/unit/lc')
      .then(data => {
        const sortedData = _.orderBy(data.data, 'priority', 'asec');
        const units = sortedData.map(unit => ({
          key: unit.lc_unit_id,
          text: unit.description,
          value: unit.description,
        }));
        this.setState({ units, loading: false });
      });
    const { curVal, stateHandler } = this.props;
    const { state, method, lc } = curVal;
    const methodType = method.method;
    if (state.value !== 6 || methodType !== 1) {
      stateHandler('lc', lc.index, { value: null, unit: null, percent: null, valid: true });
    }
  }

  handleInputBlur(event) {
    const { value } = event.target;
    const { stateHandler, curVal } = this.props;
    if (value === '') {
      this.setState({ inputErr: true });
    } else {
      this.setState({ value, inputErr: false });
      const { unit, percent } = this.state;
      const { lc } = curVal;
      if (unit !== null && percent !== null) {
        stateHandler('lc', lc.index, { unit, percent, value, index: lc.index });
      }
    }
  }

  handleSelection(event, data) {
    const { value } = data;
    const { stateHandler, curVal } = this.props;
    this.setState({ unit: value });
    const { value: val, percent } = this.state;
    const { lc } = curVal;
    if (val !== null && percent !== null) {
      stateHandler('lc', lc.index, { unit: value, percent, value: val, index: lc.index, valid: true });
    }
  }

  handlePercent(event) {
    const percent = event.target.value;
    const { stateHandler, curVal } = this.props;
    if (percent !== null) {
      const { value, unit } = this.state;
      const { lc } = curVal;
      this.setState({ percent });
      stateHandler('lc', lc.index, { value, unit, percent, index: lc.index, valid: true });
    }
  }

  handleUnknowAmount() {
    this.setState({ value: 999, unit: null });
    const { stateHandler, curVal } = this.props;
    const { lc } = curVal;
    const { percent } = this.state;
    if (percent !== null) {
      stateHandler('lc', lc.index, { value: 999, unit: null, percent, index: lc.index, valid: true });
    }
  }

  handleUnknowPercent() {
    const { value, unit } = this.state;
    const { stateHandler, curVal } = this.props;
    const { lc } = curVal;
    stateHandler('lc', lc.index, { value, unit, percent: 999, index: lc.index, valid: true });
  }

  render() {
    const { unitErr, inputErr, units, value, unit, loading } = this.state;
    const { curVal } = this.props;
    const { state, method, lc } = curVal;
    const methodType = method.method;

    return !loading ? (
      state.value === 6 && methodType === 1 ? (
        <Segment.Group>
          <Segment color="blue">
            <FormHeader
              type="h3"
              title="What was the total liquid concentrate before using your cartridge/pen for the first time?"
            />
            <Segment.Group raised stacked>
              <Segment.Group stacked color="blue">
                <Segment>
                  {inputErr && (
                    <Label size="tiny" basic color="red" pointing="below">
                      Please enter an amount
                    </Label>
                  )}
                  <Input
                    pattern="\d+(\.\d*)?"
                    type="number"
                    placeholder="Type in amount"
                    onBlur={this.handleInputBlur}
                    defaultValue={lc.value}
                  />
                </Segment>
                <Segment vertical>
                  {unitErr && (
                    <Label size="tiny" basic color="red" pointing="right">
                      Please select a unit
                    </Label>
                  )}
                  <Dropdown
                    placeholder="unit"
                    compact
                    selection
                    button
                    text={lc.unit ? lc.unit : unit || 'unit'}
                    onChange={this.handleSelection}
                    options={units}
                    value={lc.unit}
                  />
                </Segment>
                <Divider horizontal>Or</Divider>
                <Button onClick={this.handleUnknowAmount} content="Unknown" basic color="blue" />
              </Segment.Group>
            </Segment.Group>
          </Segment>
          {(value === 999 || (value !== '' && unit !== null) || lc.value !== null) && (
            <Segment color="blue">
              <FormHeader type="h3" title="Percent of entire cartridge/pen that you consumed in this session." />
              <Segment.Group raised stacked>
                <Segment.Group stacked color="blue">
                  <Input
                    pattern="\d+(\.\d*)?"
                    type="number"
                    placeholder="Type in percentage"
                    onBlur={this.handlePercent}
                    defaultValue={lc.percent}
                  />{' '}
                  % <Divider horizontal>Or</Divider>
                  <Button onClick={this.handleUnknowPercent} content="Unknown" basic color="blue" />
                </Segment.Group>
              </Segment.Group>
            </Segment>
          )}
        </Segment.Group>
      ) : (
        <React.Fragment>{null}</React.Fragment>
      )
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

PercentForm.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};

export default PercentForm;
