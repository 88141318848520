import React, { Component } from 'react';
import { Button, Modal, Icon, Input, Segment, Label, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import '../css/AddParticipant.css';
import { fetchWithToken } from '../Util/fetch';

export default class AddParticipant extends Component {
  constructor(props) {
    super(props);
    // Adding study for pain content
    this.state = {
      modalOpen: false,
      pin: '',
      studyID: '',
      startDate: '',
      study: '',
      pinErr: false,
      dateErr: false,
      studyIDErr: false,
      studyErr: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handlePIN = this.handlePIN.bind(this);
    this.handleID = this.handleID.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleStudy = this.handleStudy.bind(this);
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  handleOpen() {
    this.setState({ modalOpen: true });
  }

  handleAdd() {
    // Added study option for pt addition
    const { studyID, pin, startDate, study } = this.state;
    if (studyID.length !== 5) {
      this.setState({ studyIDErr: true });
    }
    if (!/^\d+$/.test(pin) || pin.length !== 4) {
      this.setState({ pinErr: true });
    }
    if (!moment(startDate, 'YYYY-MM-DD', true).isValid()) {
      this.setState({ dateErr: true });
    }

    if (studyID.length === 5) {
      this.setState({ studyIDErr: false });
    }
    if (/^\d+$/.test(pin) && pin.length === 4) {
      this.setState({ pinErr: false });
    }
    if (moment(startDate, 'YYYY-MM-DD', true).isValid()) {
      this.setState({ dateErr: false });
    }
    // Check if study is valid
    const isValidStudy = (study === 'CAMP' || study === 'MEIH')
    this.setState({studyErr: !isValidStudy})


    if (
      moment(startDate, 'YYYY-MM-DD', true).isValid() &&
      /^\d+$/.test(pin) &&
      pin.length === 4 &&
      studyID.length === 5 &&
      isValidStudy
    ) {
      const fields = {
        study_id: studyID.toUpperCase(),
        pt_pin: pin,
        study: study,
        active: 1,
        validated: 1,
        language: 'en-US',
        site: 'UCSD',
        start_date: moment(startDate).format('YYYY-MM-DD'),
        entry_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      fetchWithToken()
        .post('/participant/add', {
          fields,
        })
        .then(response => {
          const { id } = response.data;
          const { participants, handleAdd } = this.props;
          fields.pt_profiles = [];
          fields.pt_activities = [];
          fields.study_id = studyID;
          fields.id = id;
          participants.push(fields);
          handleAdd(participants);
        });
      this.setState({ modalOpen: false, pinErr: false, dateErr: false, studyIDErr: false });
    }
  }

  handlePIN(e, data) {
    this.setState({ pin: data.value, pinErr: false });
  }

  handleID(e, { value }) {
    this.setState({ studyID: value.toUpperCase() });
  }

  handleDate(e, { value }) {
    this.setState({ startDate: value });
  }

  handleStudy(e, { value }) {
    this.setState({ study: value.toUpperCase() })
  }

  render() {
    const { pinErr, dateErr, studyIDErr, studyErr, modalOpen } = this.state;
    // Study valid options
    const studyOptions = [
      {
        key: 'CAMP',
        text: 'CAMP',
        value: 'CAMP',
      },
      {
        key: 'MEIH',
        text: 'MEIH',
        value: 'MEIH'
      }
    ];

    return (
      <Modal
        size="mini"
        className="add-modal"
        closeIcon
        style={{ height: '25%', marginTop: '-10em' }}
        open={modalOpen}
        onClose={this.handleClose}
        trigger={
          <Button floated="right" color="green" icon onClick={this.handleOpen} labelPosition="right">
            <Icon name="add user" />
            Add
          </Button>
        }
      >
        <Modal.Header className="add-participant-header">Add New Participant</Modal.Header>
        <Modal.Content className="add-parciticpant-content">
          <Segment className="add-parciticpant-segment" stacked>
            <Label as="a" color="blue" ribbon>
              PIN
            </Label>
            {pinErr && (
              <Label basic color="red" pointing="below">
                PIN Must Be 4 Digits
              </Label>
            )}
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Enter 4 Digit Pin"
              pattern="[0-9]*"
              onChange={this.handlePIN}
            />
            <Label as="a" color="blue" ribbon>
              Study
            </Label>
            {studyErr && (
              <Label basic color="red" pointing="below">
                Invalid Study Selected
              </Label>
            )}
            <Dropdown
              placeholder='Select Study'
              fluid
              selection
              options={studyOptions}
              onChange={this.handleStudy}
            />
            <Label as="a" color="blue" ribbon>
              Study ID
            </Label>
            {studyIDErr && (
              <Label basic color="red" pointing="below">
                Study ID Must Be 5 alpha-numeric characters
              </Label>
            )}
            <Input fluid icon="user" iconPosition="left" placeholder="Enter Study ID" onChange={this.handleID} />
            <Label as="a" color="blue" ribbon>
              Start Date
            </Label>
            {dateErr && (
              <Label basic color="red" pointing="below">
                Invalid Date Format Must be YYYY-MM-DD
              </Label>
            )}
            <Input
              fluid
              icon="calendar"
              iconPosition="left"
              placeholder="Start Date (YYYY-MM-DD)"
              onChange={this.handleDate}
            />
          </Segment>
        </Modal.Content>
        <Modal.Actions className="modal-actions">
          <Button positive onClick={this.handleAdd} icon="checkmark" labelPosition="right" content="Confirm" />
          <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Cancel" />
        </Modal.Actions>
      </Modal>
    );
  }
}

AddParticipant.propTypes = {
  handleAdd: PropTypes.func,
  participants: PropTypes.array,
};
