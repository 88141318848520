import React, { Component } from 'react';
import moment from 'moment';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { fetchWithToken } from '../Util/fetch';
import { ptSubAdd } from '../Util/helper';
import NameSearch from '../ActivityComponents/nameSearch';
import StateForm from '../ActivityComponents/StateFrom';
import MethodForm from '../ActivityComponents/MethodForm';
import CBDForm from '../ActivityComponents/CBDForm';
import THCForm from '../ActivityComponents/THCForm';
import ClassificationForm from '../ActivityComponents/ClassificationForm';
import QuantityForm from '../ActivityComponents/QuantityFrom';
import ReasonForm from '../ActivityComponents/ReasonForm';
import MeasureForm from '../ActivityComponents/MeasureForm';
import ProductSourceForm from '../ActivityComponents/ProductSourceForm';
import ActivityEntryForm from '../ActivityComponents/ActivityEntryForm';
import { adminAuth } from '../Util/auth';

const components = [
  {
    comp: NameSearch,
    active: true,
    text: 'NameSearch',
    name: 'name',
  },
  {
    comp: StateForm,
    active: false,
    text: 'StateForm',
    name: 'state',
  },
  {
    comp: MethodForm,
    active: false,
    text: 'MethodForm',
    name: 'method',
  },
  {
    comp: CBDForm,
    active: false,
    text: 'CBDForm',
    name: 'CBD',
  },
  {
    comp: THCForm,
    active: false,
    text: 'THCForm',
    name: 'THC',
  },
  {
    comp: ClassificationForm,
    active: false,
    text: 'ClassificationForm',
    name: 'classification',
  },
  {
    comp: QuantityForm,
    active: false,
    text: 'QuantityForm',
    name: 'quantity',
  },
  {
    comp: MeasureForm,
    active: false,
    text: 'MeasureForm',
    name: 'measure',
  },
  {
    comp: ReasonForm,
    active: false,
    text: 'ReasonForm',
    name: 'reason',
  },
  {
    comp: ProductSourceForm,
    active: false,
    text: 'ProductSourceForm',
    name: 'source',
  },
];

const initState = {
  form: 'name',
  activeForm: 'name',
  value: '',
  finished: false,
  components,
  index: 0,
  height: '',
  values: {
    name: {
      value: '',
      addon: false,
      index: 0,
    },
    state: {
      value: '',
      index: 1,
    },
    method: {
      method: '',
      device: '',
      index: 2,
    },
    CBD: {
      rating: null,
      rep: null,
      unit: null,
      index: 3,
    },
    THC: {
      rating: null,
      rep: null,
      unit: null,
      index: 4,
    },
    classification: {
      value: [],
      index: 5,
    },
    quantity: {
      value: '',
      unit: '',
      index: 6,
    },
    measure: {
      value: null,
      index: 7,
    },
    reason: {
      value: [],
      index: 8,
    },
    source: {
      value: '',
      rep: '',
      index: 9,
    },
  },
};

class AdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = { studyID: '', auth: true };
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    adminAuth().then(
      response => {
        const { data } = response;
        this.setState({ auth: data.auth });
        const { match } = this.props;
        const { id } = match.params;
        fetchWithToken()
          .get(`/participant/studyID/${id}`)
          .then(rep => {
            const studyID = rep.data.study_id;
            this.setState({ studyID });
          });
      },
      () => {
        this.setState({ auth: false });
      }
    );
  }

  submitHandler(values) {
    const activityTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    const substanceFields = {
      name: values.name.value,
      state_id: values.state.value,
      thc_concentrate: values.THC.rep,
      thc_unit: values.THC.unit,
      cbd_concentrate: values.CBD.rep,
      cbd_unit: values.CBD.unit,
      thc_strength_id: values.THC.rep !== null ? null : values.THC.rating,
      cbd_strength_id: values.CBD.rep !== null ? null : values.CBD.rating,
      is_active: 1,
      is_study_approved: 0,
      classification_id: values.classification.value.join(),
      entry_date: activityTime,
    };

    fetchWithToken()
      .post('/substance/add', {
        fields: substanceFields,
      })
      .then(response => {
        const substanceID = response.data.id;
        const { addon } = values.name;
        const { match } = this.props;
        const ptProfileFields = {
          participant_id: match.params.id,
          substance_id: substanceID,
          method_id: values.method.method,
          device_id: values.method.device ? values.method.device : null,
          quantity: values.quantity.value,
          unit_id: values.quantity.unit,
          reason_id: values.reason.value.join(),
          is_active: 1,
          entry_date: activityTime,
          measure: values.measure.value,
          source: values.source.value,
          source_rep: values.source.rep,
        };
        fetchWithToken().post('/pt_profile/add', {
          fields: ptProfileFields,
        });

        if (addon) {
          ptSubAdd(substanceID, match.params.id);
        }
      });
  }

  render() {
    const { auth } = this.state;

    const resetComp = initState.components.map((comp, i) => ({
      comp: comp.comp,
      active: i < 1,
      text: comp.text,
      name: comp.name,
    }));

    initState.components = resetComp;

    const { studyID } = this.state;
    const { match } = this.props;

    return !auth ? (
      <Redirect
        push
        to={{
          pathname: '/',
          state: { unauth: true },
        }}
      />
    ) : (
      <ActivityEntryForm
        initialState={initState}
        endForm="source"
        stateHandler={this.stateHandler}
        titleName={`Entry For Participant : ${studyID}`}
        height="4500px"
        studyID={studyID}
        participantID={match.params.id}
        linkBack="/participants"
        submitHandler={this.submitHandler}
      >
        <Menu fixed="top" color="blue" widths={2}>
          <Menu.Item name="CAMP" />
          <Menu.Item as={Link} to="/participants" content="Participants" />
        </Menu>
      </ActivityEntryForm>
    );
  }
}
export default withRouter(AdminForm);

AdminForm.propTypes = {
  match: PropTypes.any,
};
