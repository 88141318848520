import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Responsive, Container } from 'semantic-ui-react';
import '../css/CampLayout.css';

const CampLayout = ({ height, children }) => (
  <div className="layout-form">
    <style>{`
        div.layout-form {
            height: 100%;
        }
    `}</style>
    <Responsive as={Container} fluid style={{ marginTop: '4em' }}>
      <Grid className="layout-grid" textAlign="center" style={{ height }}>
        <Grid.Column style={{ maxWidth: 375 }}>{children}</Grid.Column>
      </Grid>
    </Responsive>
  </div>
);

CampLayout.propTypes = {
  children: PropTypes.any,
  height: PropTypes.string,
};

export default CampLayout;
