import React from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../css/TitleHeader.css';

const TitleHeader = ({ type, children, title }) => (
  <div>
    <Header className="title-header" as={type} textAlign="center">
      {children} {title}
    </Header>
  </div>
);

TitleHeader.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.array,
};

export default TitleHeader;
