import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Loader, Segment, Menu, List } from 'semantic-ui-react';
import moment from 'moment';
import { fetchWithToken } from '../Util/fetch';
import ActivityList from '../UIComponents/ActivityList';
import ActivityEntryView from '../UIComponents/ActivityEntryView';

class RecentActivities extends Component {
  constructor(props) {
    super(props);
    this.state = { activities: [], classifications: [], loading: true };
  }

  async componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const splitData = decoded.split('&');
    const studyID = splitData[1];

    const { data: id } = await fetchWithToken().get(`/participant/id/studyID/${studyID}`);

    const activitiesUrl = `/participant/activities/${id}`;
    const { data: activities } = await fetchWithToken().get(activitiesUrl);
    const { data: classifications } = await fetchWithToken().get('/classification');

    this.setState({ activities, classifications, loading: false });
  }

  render() {
    const { activities, classifications, loading } = this.state;
    const { match, prev } = this.props;
    let activityList = [];
    if (activities.length) {
      activityList = activities[0].pt_activities.map(activity =>
        activity.pt_activitysubs.map(ptSubs => (
          <List.Item key={`recent-${ptSubs.id}`}>
            <List.Header>
              <ActivityEntryView
                entry={ptSubs}
                header={ptSubs.substances[0].name}
                title={`Entered on ${moment(ptSubs.entry_date).format('MM-DD-YYYY hh:mm A')}`}
                participantID={match.params.id}
                entryID={ptSubs.id}
                editType="recent"
                classifications={classifications}
                prev={prev}
              />
            </List.Header>
          </List.Item>
        ))
      );
    }

    return !loading ? (
      <ActivityList
        prev={prev}
        activities={activityList}
        title="Recent Activities"
        subtitle="List of Recent Activities"
      >
        <Menu fixed="top" color="blue" widths={2}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}${prev ? '/prev' : ''}`} content="Home" />
          <Menu.Item as={Link} to={`/participant/${match.params.id}/typical${prev ? '/prev' : ''}`} content="Typical" />
        </Menu>
      </ActivityList>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(RecentActivities);

RecentActivities.propTypes = {
  match: PropTypes.object,
  prev: PropTypes.bool,
};
