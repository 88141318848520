import React from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../css/FormHeader.css';

const FormHeader = ({ type, title, children }) => (
  <div>
    <Header className="camp-header" as={type} textAlign="center">
      {children} {title}
    </Header>
  </div>
);

FormHeader.propTypes = {
  title: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.array,
};

export default FormHeader;
