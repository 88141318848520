import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConcentrationSlider from '../UIComponents/ConcentrationSlider';

export default class CBDForm extends Component {
  constructor(props) {
    super(props);
    this.stateHandler = this.stateHandler.bind(this);
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { CBD } = curVal;
    const edit = !!CBD.edit;
    stateHandler('CBD', CBD.index, { ...value, index: CBD.index, edit });
  }

  render() {
    const { curVal } = this.props;
    const marks = {
      1: (
        <strong>
          1<br />
          Weak
        </strong>
      ),
      2: <strong>2</strong>,
      3: (
        <strong>
          3<br />
          Moderate
        </strong>
      ),
      4: <strong>4</strong>,
      5: (
        <strong>
          5<br />
          Strong
        </strong>
      ),
    };

    return (
      <ConcentrationSlider
        curVal={curVal.CBD}
        title="CBD"
        marks={marks}
        text="Select Concentration"
        stateHandler={this.stateHandler}
        edit={!!curVal.CBD.edit}
      />
    );
  }
}

CBDForm.propTypes = {
  stateHandler: PropTypes.func,
  curVal: PropTypes.any,
};
