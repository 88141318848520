import axios from 'axios';
import base from './endpoints';

export default axios.create({
  baseURL: base,
  timeout: 8000,
  headers: { 'x-access-token': 'foobar' },
});

export function fetchWithToken() {
  const token = sessionStorage.getItem('token');
  return axios.create({
    baseURL: base,
    timeout: 8000,
    headers: { 'x-access-token': token },
  });
}
