import React, { Component } from 'react';
import { Segment, Button, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FormHeader from '../UIComponents/FormHeader';
import ButtonGroupForm from '../UIComponents/ButtonGroupForm';
import CheckOptions from '../UIComponents/CheckOptions';

export default class WeeklyConditional extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = {
      yesno: curVal.yesno,
      treatment: curVal.treatment,
      explain: curVal.explain,
      show: false,
      more: curVal.treatment,
    };
    this.formStateHandler = this.formStateHandler.bind(this);
    this.secondHandle = this.secondHandle.bind(this);
    this.thridHandle = this.thridHandle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.fourthHandle = this.fourthHandle.bind(this);
  }

  formStateHandler(option) {
    const { treatment, second } = this.state;
    const { conditionalValues, formHandler } = this.props;
    this.setState({ yesno: option });
    if (!conditionalValues.includes(option)) {
      this.setState({ show: false });
      formHandler({
        yesno: option,
        treatment: null,
        explain: null,
        more: null,
        done: 'yes',
        valid: true,
      });
    } else if (second !== '') {
      formHandler({
        yesno: option,
        treatment,
        explain: null,
        more: null,
        done: 'no',
        valid: true,
      });
    }
  }

  secondHandle(option) {
    const { treatment, yesno } = this.state;
    const { secondConditionalValues, formHandler } = this.props;
    this.setState({ treatment: option });
    if (option !== '' && !treatment.includes(option)) {
      formHandler({
        yesno,
        treatment: option,
        explain: null,
        more: null,
        done: 'no',
        valid: true,
      });
    }
    if (!option.includes(secondConditionalValues)) {
      this.setState({ show: true });
    }
  }

  thridHandle(option) {
    this.setState({
      explain: option,
    });
  }

  fourthHandle(option) {
    const { yesno, treatment, explain } = this.state;
    const { formHandler } = this.props;
    formHandler({
      yesno,
      treatment,
      explain,
      more: option,
      done: 'yes',
      valid: true,
    });
  }

  handleSubmit() {
    this.setState({ show: true });
  }

  handleOnChange(e, { value }) {
    const { yesno, treatment, more } = this.state;
    const { formHandler } = this.props;
    this.setState({ explain: value });
    formHandler({
      yesno,
      treatment,
      explain: value,
      more,
      valid: true,
    });
  }

  render() {
    const { yesno, treatment, show } = this.state;
    const {
      title,
      curVal,
      multi,
      options,
      conditionalValues,
      optionData,
      secondConditionalValues,
      moreData,
    } = this.props;
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" color="blue" title={title} />
          <Segment.Group raised stacked>
            <Segment color="blue">
              <ButtonGroupForm
                curVal={curVal.yesno}
                formStateUpdate={this.formStateHandler}
                options={options}
                multi={multi}
              />
            </Segment>
            {conditionalValues.includes(yesno) && (
              <CheckOptions
                formHandler={this.secondHandle}
                title="Select Treatments"
                options={optionData}
                curVal={[]}
                allOrNone={false}
              />
            )}
            {treatment.includes(secondConditionalValues) && (
              <Segment.Group raised stacked>
                <Segment>
                  <FormHeader
                    type="h3"
                    color="blue"
                    title={
                      <React.Fragment>
                        <div>Explain Non-traditional Treatment</div>
                        <div>(Optional. Tap OK to skip). </div>
                      </React.Fragment>
                    }
                  />
                </Segment>
                <Segment color="blue">
                  <Input
                    action={<Button positive content="OK" onClick={this.handleSubmit} />}
                    placeholder="Enter Answer"
                    onChange={this.handleOnChange}
                  />
                </Segment>
              </Segment.Group>
            )}
            {show && (
              <CheckOptions
                formHandler={this.fourthHandle}
                title="Did You Use Anything Else ?"
                options={moreData}
                curVal={[]}
                allOrNone={false}
                none
              />
            )}
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

WeeklyConditional.propTypes = {
  curVal: PropTypes.any,
  formHandler: PropTypes.func,
  options: PropTypes.array,
  multi: PropTypes.bool,
  title: PropTypes.string,
  optionData: PropTypes.array,
  conditionalValues: PropTypes.array,
  secondConditionalValues: PropTypes.number,
  moreData: PropTypes.array,
};
