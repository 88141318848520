import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/confetti.css';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormHeader from './FormHeader';

export default class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.stateHandler = this.stateHandler.bind(this);
  }

  componentDidMount() {
    const { curVal, stateHandler, past } = this.props;
    if (curVal !== null && curVal !== undefined) {
      const time = moment.utc(curVal).format('HH:mm:ss');
      const date = past ? moment.utc(curVal).format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD');
      const dateTime = moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss');
      stateHandler(dateTime);
    } else {
      stateHandler(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
    }
  }

  stateHandler(value) {
    const { stateHandler, past, curVal } = this.props;
    if (past) {
      const time = moment.utc(value[0]).format('HH:mm:ss');
      const date = past ? moment.utc(curVal).format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD');
      const dateTime = moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss');
      stateHandler(dateTime);
    } else {
      const time = moment.utc(value[0]).format('YYYY-MM-DD HH:mm:ss');
      stateHandler(time);
    }
  }

  render() {
    const { curVal, title, subtitle } = this.props;
    const time = moment(curVal).format('hh:mmA');
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title={title} />
          <Segment.Group raised stacked>
            <Segment textAlign="center">
              <FormHeader type="h4" title={subtitle} />
              <Flatpickr
                data-enable-time
                options={{
                  defaultDate: curVal ? time : moment().format('hh:mmA'),
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'h:i K',
                  minuteIncrement: 1,
                }}
                onClose={this.stateHandler}
              />
            </Segment>
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

TimePicker.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  stateHandler: PropTypes.func,
  curVal: PropTypes.string,
  past: PropTypes.bool,
};
