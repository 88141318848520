import React, { Component } from 'react';
import { Input, Segment, Dropdown, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';

export default class InputAndDropdown extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = { value: curVal.value, unit: curVal.unit, valueErr: false };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleInputChange(event) {
    const { value } = event.target;
    this.setState({ value });
  }

  handleChange(e, { value }) {
    console.log(value);
    const { value: amount } = this.state;
    const { stateHandler } = this.props;
    if (value !== '' && !amount && Number(amount) === 0) {
      console.log('1');
      this.setState({ unit: value });
    }
    if (value !== '' && amount && Number(amount) !== 0 && !Number.isNaN(Number(amount))) {
      console.log('2');
      this.setState({ unit: value, valueErr: false });
      stateHandler(Number(amount), value, true);
    } else {
      console.log('3');
      stateHandler(Number(amount), value, false);
    }
  }

  handleBlur() {
    const { value, unit } = this.state;
    const { stateHandler } = this.props;
    if (value === '' || Number.isNaN(Number(value))) {
      this.setState({ valueErr: true });
    } else {
      this.setState({ valueErr: false });
    }
    if (unit && value !== '' && Number(value) !== 0 && !Number.isNaN(Number(value))) {
      this.setState({ valueErr: false });
      stateHandler(Number(value), unit, true);
    } else {
      stateHandler(Number(value), unit, false);
    }
  }

  render() {
    const { unit, valueErr } = this.state;
    const { title, placeholder, curVal, options, text } = this.props;
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title={title} />
          <Segment.Group raised stacked>
            <Segment compact color="blue">
              {valueErr && (
                <Label basic color="red">
                  Please enter a number
                </Label>
              )}
              <Input
                pattern="\d+(\.\d*)?"
                placeholder={placeholder}
                onChange={this.handleInputChange}
                defaultValue={curVal.value}
                fluid
                onBlur={this.handleBlur}
              />
              <Dropdown
                options={options}
                placeholder={text}
                search
                selection
                fluid
                value={unit}
                onChange={this.handleChange}
              />
            </Segment>
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

InputAndDropdown.propTypes = {
  curVal: PropTypes.any,
  title: PropTypes.string,
  stateHandler: PropTypes.func,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  options: PropTypes.array,
};
