import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import { fetchWithToken } from '../Util/fetch';
import CheckOptions from '../UIComponents/CheckOptions';

export default class PainReasons extends Component {
  constructor(props) {
    super(props);
    this.state = { reasons: [], loading: false };
    this.stateHandler = this.stateHandler.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { data: rawReasons } = await fetchWithToken().get('/pain_impact');
    const reasons = rawReasons.map(reason => ({ id: reason.pain_impact_id, name: reason.description }));
    this.setState({ reasons, loading: false });
  }

  stateHandler(value) {
    const { stateHandler } = this.props;
    stateHandler('reasons', '5', value);
  }

  render() {
    const { reasons, loading } = this.state;
    const { curVal } = this.props;

    return !loading ? (
      <CheckOptions
        formHandler={this.stateHandler}
        title={
          <React.Fragment>
            <div>Please mark the reason(s) your pain was worse today, if any.</div>
            <div>(Mark all that apply)</div>
          </React.Fragment>
        }
        options={reasons}
        curVal={curVal.reasons}
        allOrNone
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

PainReasons.propTypes = {
  stateHandler: PropTypes.func,
  curVal: PropTypes.any,
};
