import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConcentrationSlider from '../UIComponents/ConcentrationSlider';

export default class THCForm extends Component {
  constructor(props) {
    super(props);
    this.stateHandler = this.stateHandler.bind(this);
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { THC } = curVal;
    const edit = !!THC.edit;
    stateHandler('THC', THC.index, { ...value, index: THC.index, edit });
  }

  render() {
    const { curVal } = this.props;
    const marks = {
      1: (
        <strong>
          1<br />
          Weak
        </strong>
      ),
      2: <strong>2</strong>,
      3: (
        <strong>
          3<br />
          Moderate
        </strong>
      ),
      4: <strong>4</strong>,
      5: (
        <strong>
          5<br />
          Strong
        </strong>
      ),
    };

    return (
      <ConcentrationSlider
        curVal={curVal.THC}
        title="THC"
        marks={marks}
        text="Select Concentration"
        stateHandler={this.stateHandler}
        edit={!!curVal.THC.edit}
      />
    );
  }
}

THCForm.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};
