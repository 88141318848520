import React, { Component } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Card, Menu, Input, Loader, Segment } from 'semantic-ui-react';
import CampLayout from '../UIComponents/CampLayout';
import TitleHeader from '../UIComponents/TitleHeader';
import '../css/AdminParticipantList.css';
import { fetchWithToken } from '../Util/fetch';
import ParticipantActivation from './ParticipantActivation';

class InactiveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: [],
      filterdParticipants: [],
      logout: false,
      loading: true,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReactivate = this.handleReactivate.bind(this);
  }

  async componentDidMount() {
    const { data: inactivePts } = await fetchWithToken().get('/participant/inactive');
    this.setState({ participants: inactivePts, filterdParticipants: inactivePts, loading: false });
  }

  handleLogout() {
    this.setState({ logout: true });
  }

  handleSearch(event, data) {
    const { value } = data;
    const { participants } = this.state;
    const filterdParticipants = participants.filter(particpant => particpant.study_id.includes(value.toUpperCase()));
    this.setState({ filterdParticipants });
  }

  async handleReactivate(participantID) {
    const { filterdParticipants } = this.state;
    const newParticipantList = filterdParticipants.filter(pt => pt.id !== participantID);
    await fetchWithToken().post('/participant/admin/activate', {
      active: 1,
      id: participantID,
    });
    this.setState({ filterdParticipants: newParticipantList });
  }

  render() {
    const { filterdParticipants, loading, logout } = this.state;

    if (logout) {
      return (
        <Redirect
          push
          to={{
            pathname: '/',
            state: { logout: true },
          }}
        />
      );
    }

    const participantsList = filterdParticipants.map(participant => (
      <Card fluid raised color="blue" key={`card-${participant.id}`}>
        <Card.Content>
          <Card.Header textAlign="left" className="card-header">
            StudyID : {participant.study_id}
          </Card.Header>
        </Card.Content>
        <Card.Content extra>
          <ParticipantActivation
            participantID={participant.id}
            studyID={participant.study_id}
            activationHandler={this.handleReactivate}
            triggerText="Reactivate Participant"
            confirmationText="Confirm Reactivation of Participant"
          />
        </Card.Content>
      </Card>
    ));

    return !loading ? (
      <CampLayout height="100%">
        <Menu fixed="top" widths={4}>
          <Menu.Item header>CAMP</Menu.Item>
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={this.handleSearch} />
          </Menu.Item>
          <Menu.Item as={Link} to="/participants/" content="Active Participants" />
          <Menu.Item onClick={this.handleLogout} content="Logout" />
        </Menu>
        <TitleHeader type="h2" title="Inactive Participants" />
        <Card.Group className="card-group">
          {participantsList.length ? (
            participantsList
          ) : (
            <Card fluid raised color="blue">
              <Card.Content>
                <Card.Header textAlign="center" className="card-header">
                  No Inactive Participants
                </Card.Header>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
      </CampLayout>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(InactiveList);
