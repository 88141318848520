import React, { Component } from 'react';
import { Message, Button } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchWithToken } from '../Util/fetch';
import '../css/Compliance.css';
import CampLayout from '../UIComponents/CampLayout';

class Compliance extends Component {
  constructor(props) {
    super(props);
    this.state = { compliance: '' };
  }

  componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const splitData = decoded.split('&');
    const studyID = splitData[1];
    fetchWithToken()
      .get(`/participant/id/studyID/${studyID}`)
      .then(response => {
        const id = response.data;
        return id;
      })
      .then(response => fetchWithToken().get(`/participant/compliance/${response}`))
      .then(responseComp => {
        const { data } = responseComp;
        const { compliance } = data;
        this.setState({ compliance });
      })
      .then(() => {
        fetchWithToken().post('/surveys/', {
          studyID,
        });
      });
  }

  render() {
    const { compliance } = this.state;
    const { match } = this.props;
    return (
      compliance && (
        <CampLayout>
          <div className="compliance-message">
            <Message positive>
              <Message.Header>Thank You For Completing Your Entries.</Message.Header>
              <p>You have completed {parseFloat(compliance).toFixed(0)}% of your surveys </p>
            </Message>
            <Link to={`/participant/${match.params.id}`}>
              <Button positive>OK</Button>
            </Link>
          </div>
        </CampLayout>
      )
    );
  }
}

export default withRouter(Compliance);

Compliance.propTypes = {
  match: PropTypes.object,
};
