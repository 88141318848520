import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment, Input, Label } from 'semantic-ui-react';
import lo from 'lodash';

import { fetchWithToken } from '../Util/fetch';
import FormHeader from '../UIComponents/FormHeader';
import ButtonGroupForm from '../UIComponents/ButtonGroupForm';

export default class ProductSourceForm extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = {
      sources: [],
      value: curVal.source.value,
      rep: curVal.source.rep,
      conditionalValues: [1, 2, 4, 7, 8, 10],
      inputError: false,
      loading: true,
    };
    this.stateHandler = this.stateHandler.bind(this);
    this.buttonHanlder = this.buttonHanlder.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.onChangeHanlder = this.onChangeHanlder.bind(this);
  }

  async componentDidMount() {
    const { data: rawSource } = await fetchWithToken().get('/product_source');
    const sources = lo
      .orderBy(rawSource, 'priority', 'asec')
      .map(source => ({ id: source.id, name: source.description }));
    this.setState({ sources, loading: false });
  }

  onChangeHanlder(_, data) {
    const { value } = data;
    this.setState({ value });
  }

  stateHandler(value) {
    const { stateHandler } = this.props;
    stateHandler('source', '9', value);
  }

  buttonHanlder(value) {
    this.setState({ value });
    const { conditionalValues, rep } = this.state;
    const { curVal, stateHandler } = this.props;

    if (!conditionalValues.includes(value)) {
      stateHandler('source', curVal.source.index, {
        value,
        rep: '',
        index: curVal.source.index,
        valid: true,
      });
    } else if ((curVal.source.rep || rep) && conditionalValues.includes(value)) {
      stateHandler('source', curVal.source.index, {
        value,
        rep,
        index: curVal.source.index,
        valid: true,
      });
    } else {
      stateHandler('source', curVal.source.index, {
        value,
        rep,
        index: curVal.source.index,
        valid: false,
      });
    }
  }

  inputHandler(data) {
    const { conditionalValues, value } = this.state;
    const { curVal, stateHandler } = this.props;
    const val = data.target.value;
    const { source } = curVal;
    if (conditionalValues.includes(value)) {
      if (val !== '') {
        this.setState({ inputError: false });
        stateHandler('source', source.index, { value, rep: val, index: source.index, valid: true });
      } else {
        this.setState({ inputError: true });
        stateHandler('source', source.index, { value, rep: val, index: source.index, valid: false });
      }
    } else if (!conditionalValues.includes(value)) {
      stateHandler('source', source.index, { value, rep: val, index: source.index, valid: true });
    }
  }

  render() {
    const { sources, value, conditionalValues, rep, inputError, loading } = this.state;
    const { curVal } = this.props;
    return !loading ? (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title="Source of Product" />
          <Segment.Group raised stacked>
            <Segment color="blue">
              <ButtonGroupForm curVal={curVal.source.value} formStateUpdate={this.buttonHanlder} options={sources} />
            </Segment>
            {conditionalValues.includes(value) && (
              <Segment raised color="blue">
                {inputError && (
                  <Label pointing="below" color="red" basic>
                    Please enter a source.
                  </Label>
                )}
                <Input
                  error={inputError}
                  placeholder="Enter Source"
                  value={rep === null ? '' : rep}
                  onChange={(_, data) => this.setState({ rep: data.value })}
                  fluid
                  onBlur={this.inputHandler}
                />
              </Segment>
            )}
          </Segment.Group>
        </Segment>
      </Segment.Group>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

ProductSourceForm.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};
