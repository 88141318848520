import React, { Component } from 'react';
import { Segment, Button, Label, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../css/CheckOptions.css';
import FormHeader from './FormHeader';
import ButtonForm from './ButtonForm';

export default class CheckOptions extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = { options: curVal, error: false, updated: false, isOpen: false };
    this.formStateHandler = this.formStateHandler.bind(this);
    this.buttonClickHandler = this.buttonClickHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    const { options } = this.state;
    const { formHandler } = this.props;
    if (options.length) {
      formHandler(options);
      this.setState({ error: false, isOpen: true, updated: true });
    } else {
      this.setState({ error: true });
    }
  }

  formStateHandler(option) {
    const { formHandler } = this.props;
    this.setState({ options: option });
    formHandler(option);
  }

  buttonClickHandler(value) {
    const { options: propOptions, allOrNone, none: propNone } = this.props;
    const { options: stateOptions } = this.state;
    const opt = propOptions;
    const { classification } = this.props;
    const options = opt.map(option => option.id);
    let all;
    let none;
    const optionsLength = options.length;
    if (allOrNone) {
      all = propOptions[optionsLength - 2].id;
      none = propOptions[optionsLength - 1].id;
    } else if (propNone) {
      none = propOptions[optionsLength - 1].id;
      all = 100000;
    } else {
      all = 100000;
      none = 9999999;
    }

    const currentOptions = new Set(stateOptions);
    const lowered = value.name.toLowerCase();

    let newOptions;
    if ((lowered.includes('none') || lowered.includes('unknown')) && !currentOptions.has(value.id)) {
      newOptions = new Set([value.id]);
    } else if (lowered.includes('all') && !currentOptions.has(value.id)) {
      newOptions = new Set([value.id]);
    } else if (classification && lowered.includes('indica') && currentOptions.has(2)) {
      currentOptions.delete(2);
      newOptions = currentOptions.add(value.id);
    } else if (classification && lowered.includes('sativa') && currentOptions.has(1)) {
      currentOptions.delete(1);
      newOptions = currentOptions.add(value.id);
    } else if (!currentOptions.has(value.id)) {
      if (currentOptions.has(all)) {
        currentOptions.delete(all);
        newOptions = currentOptions.add(value.id);
      } else if (currentOptions.has(none)) {
        currentOptions.delete(none);
        newOptions = currentOptions.add(value.id);
      } else {
        newOptions = currentOptions.add(value.id);
      }
    } else {
      currentOptions.delete(value.id);
      newOptions = currentOptions;
    }
    this.setState({ options: Array.from(newOptions) });
  }

  activeHandler(option) {
    const { options } = this.state;
    const currentOptions = options;
    return currentOptions.includes(option.id);
  }

  render() {
    const { options, edit, title } = this.props;
    const { error, updated, isOpen } = this.state;

    if (edit && updated === true) {
      setTimeout(() => {
        this.setState({ updated: false, isOpen: false });
      }, 4000);
    }

    const optionList = options.map(option => (
      <ButtonForm
        key={`option-${option.name}`}
        buttonName={option.name}
        formClickHandler={() => this.buttonClickHandler(option)}
        activeState={this.activeHandler(option)}
      />
    ));
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title={title} />
          {error && (
            <Label basic color="red">
              Please select a value
            </Label>
          )}
          <Segment.Group raised stacked>
            <Segment color="blue">
              <Button.Group fluid basic vertical>
                {optionList}
              </Button.Group>
            </Segment>
            <Segment className="multi-next">
              {edit ? (
                <Popup
                  trigger={
                    <Button
                      content={edit ? 'Update' : 'Next'}
                      basic
                      color="blue"
                      icon="right arrow"
                      labelPosition="right"
                    />
                  }
                  content={<Label color="green">Updated</Label>}
                  on="click"
                  open={isOpen}
                  onOpen={this.onClickHandler}
                  position="left center"
                />
              ) : (
                <Button
                  onClick={this.onClickHandler}
                  content={edit ? 'Update' : 'Next'}
                  basic
                  color="blue"
                  icon="right arrow"
                  labelPosition="right"
                />
              )}
            </Segment>
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

CheckOptions.propTypes = {
  curVal: PropTypes.any,
  options: PropTypes.array,
  title: PropTypes.any,
  formHandler: PropTypes.func,
  allOrNone: PropTypes.bool,
  none: PropTypes.bool,
  classification: PropTypes.bool,
  edit: PropTypes.bool,
};
