import React, { Component } from 'react';
import { Button, Modal, Segment, Label, List, Header, Loader } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormHeader from '../UIComponents/FormHeader';
import '../css/AdminParticipantView.css';
import AdminParticipantEntryView from './AdminParticipantEntryView';
import AdminParticipantWeeklyEntryView from './AdminParticipantWeeklyEntryView';
import AdminParticipantPainEntryView from './AdminParticipantPainEntryView';
import AdminParticipantNoActivityView from './AdminParticipantNoActivityView';
import { fetchWithToken } from '../Util/fetch';

export default class AdminParticipantView extends Component {
  constructor(props) {
    super(props);
    // Added ignorePainContent to check if pain entry section should be displayed
    this.state = { modalOpen: false, ptEntries: [], weeklyEntries: '', painEntries: [], loading: true, ignorePainContent: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  async componentDidMount() {
    // Adding studyID param to get the pt Study
    const { participantID, studyID } = this.props;
    const ptEntriesUrl = `/participant/admin/entries/${participantID}`;
    const weeklyUrl = `/participant/admin/weekly/${participantID}`;
    // const painUrl = `/participant/admin/pain/${participantID}`;

    const { data: ptEntries } = await fetchWithToken().get(ptEntriesUrl);
    const { data: weeklyEntries } = await fetchWithToken().get(weeklyUrl);
    // const { data: painEntries } = await fetchWithToken().get(painUrl);

    const painEntries = ptEntries[0].pt_activities.filter(act => act.status_id === 2).map(act => {
      const { pt_activitysubs: actSubs, ...rest } = act;
      const filteredPtSubs = actSubs.filter(
        sub => sub.pain_before !== null && sub.pain_after !== null && sub.status_id === 2
      );
      return {
        ...rest,
        pt_activitysubs: filteredPtSubs,
      };
    });

    // Update the ignorePainContent depending on the given pt Study
    await fetchWithToken()
      .get(`/participant/data/studyID/${studyID}`)
      .then(response => {
        const study = response.data.study;
        const ignorePainContent = study !== "CAMP";
        this.setState({ ignorePainContent });
      });

    this.setState({ ptEntries, weeklyEntries, painEntries, loading: false });
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  handleOpen() {
    this.setState({ modalOpen: true });
  }

  render() {
    const {
      entries,
      classifications,
      strength,
      pain,
      painReasons,
      treatmentsList,
      activityReasons,
      sources,
      studyID,
      participantID,
      startDate,
    } = this.props;
    // Added ignorePainContent use to check if pain section should be displayed
    const { ptEntries, weeklyEntries, painEntries, modalOpen, loading, ignorePainContent } = this.state;
    let ptEntriesList = [];
    if (ptEntries.length) {
      ptEntriesList = ptEntries[0].pt_activities.map(activity => {
        if (activity.pt_activitysubs.length) {
          return activity.pt_activitysubs.map(ptSubs => (
            <List.Item key={`entry-${ptSubs.id}`}>
              <AdminParticipantEntryView
                entry={ptSubs}
                studyID={studyID}
                classifications={classifications}
                strength={strength}
                activityReasons={activityReasons}
                sources={sources}
              />
            </List.Item>
          ));
        }
        return (
          <List.Item key={`entry-${activity.visit_id}`}>
            <List.Header>No Activity Entries For {moment(activity.activity_date).format('YYYY-MM-DD')}</List.Header>
          </List.Item>
        );
      });
    }

    let weeklyEntriesList = [];
    if (weeklyEntries) {
      if (weeklyEntries.pt_activity_others.length) {
        weeklyEntriesList = weeklyEntries.pt_activity_others.map(weekly => {
          if (weekly.pt_activitysub_others.length) {
            return weekly.pt_activitysub_others.map(ptSubs => (
              <List.Item key={`weekly-${ptSubs.id}`}>
                <AdminParticipantWeeklyEntryView
                  entry={ptSubs}
                  studyID={studyID}
                  usingOther={weekly.is_using_other}
                  date={weekly.entry_date}
                  treatmentsList={treatmentsList}
                />
              </List.Item>
            ));
          }

          return (
            <List.Item key={`No-${weekly.entry_date}`}>
              <AdminParticipantWeeklyEntryView
                studyID={studyID}
                usingOther={weekly.is_using_other}
                date={weekly.entry_date}
              />
            </List.Item>
          );
        });
      }
    }

    let painEntriesList = [];
    if (painEntries.length) {
      painEntriesList = painEntries.map(entry => {
        if (entry.pt_activitysubs.length) {
          return entry.pt_activitysubs.map(ptSub => (
            <List.Item key={`pain-${ptSub.id}`}>
              <AdminParticipantPainEntryView
                entry={ptSub}
                morning={entry.morning_pain}
                now={entry.now_pain}
                highest={entry.highest_pain}
                reason={entry.reason_pain}
                interference={entry.interference_pain}
                studyID={studyID}
                classifications={classifications}
                strength={strength}
                pain={pain}
                painReasons={painReasons}
                sources={sources}
                activityReasons={activityReasons}
              />
            </List.Item>
          ));
        }
        return (
          <List.Item key={`pain-${entry.visit_id}`}>
            <AdminParticipantNoActivityView
              morning={entry.morning_pain}
              now={entry.now_pain}
              highest={entry.highest_pain}
              reason={entry.reason_pain}
              interference={entry.interference_pain}
              studyID={studyID}
              pain={pain}
              painReasons={painReasons}
              date={entry.entry_date}
            />
          </List.Item>
        );
      });
    }

    const entryList = entries.map(entry => (
      <List.Item key={`typical-${entry.id}`}>
        <AdminParticipantEntryView
          studyID={studyID}
          entry={entry}
          classifications={classifications}
          activityReasons={activityReasons}
          sources={sources}
          strength={strength}
          type="typical"
        />
      </List.Item>
    ));

    return !loading ? (
      <Modal
        size="small"
        className="add-modal"
        open={modalOpen}
        trigger={
          <Button fluid basic color="blue" onClick={this.handleOpen}>
            View Participant
          </Button>
        }
      >
        <Modal.Header className="participant-view-header">Participant : {studyID}</Modal.Header>
        <Modal.Content scrolling>
          <Segment.Group color="blue">
            <Segment>
              <Label color="blue" ribbon>
                Start Date
              </Label>
              {moment(startDate).format('MM-DD-YYYY')}
            </Segment>
            <Segment>
              <Label color="blue" ribbon>
                STEM Link
              </Label>
              <a href={`https://camp.cstem.ucsd.edu/interface_camp/index.php?studyid=${studyID}`}>
                {' '}
                Link to Participant stem page{' '}
              </a>
            </Segment>
            <Segment>
              <Label color="blue" ribbon>
                URL
              </Label>
              {`https://camp.cstem.ucsd.edu/interface_camp/index.php?studyid=${studyID}`}
            </Segment>
          </Segment.Group>
          {entryList.length ? (
            <Segment.Group>
              <Segment>
                <Header textAlign="center" size="small" content="Typical Activities" />
              </Segment>
              <Segment>
                <List selection divided>
                  {entryList}
                </List>
              </Segment>
            </Segment.Group>
          ) : (
            <Segment.Group color="blue">
              <FormHeader type="h3" title="No Typical Activities" />{' '}
            </Segment.Group>
          )}
          {ptEntriesList.length ? (
            <Segment.Group>
              <Segment>
                <Header textAlign="center" size="small" content="Daily Entries" />
              </Segment>
              <Segment>
                <List selection divided>
                  {ptEntriesList}
                </List>
              </Segment>
            </Segment.Group>
          ) : (
            <Segment.Group color="blue">
              <FormHeader type="h3" title="No Daily Entries" />{' '}
            </Segment.Group>
          )}
          {weeklyEntriesList.length ? (
            <Segment.Group>
              <Segment>
                <Header textAlign="center" size="small" content="Weekly Entries" />
              </Segment>
              <Segment>
                <List selection divided>
                  {weeklyEntriesList}
                </List>
              </Segment>
            </Segment.Group>
          ) : (
            <Segment.Group color="blue">
              <FormHeader type="h3" title="No Weekly Entries" />{' '}
            </Segment.Group>
          )}
          {
            // Display pain activity if ignorePainContent flag is false
            painEntriesList.length  && !ignorePainContent ? (
            <Segment.Group>
              <Segment>
                <Header textAlign="center" size="small" content="Pain Entries" />
              </Segment>
              <Segment>
                <List selection divided>
                  {painEntriesList}
                </List>
              </Segment>
            </Segment.Group>
          ) : (
            <Segment.Group color="blue">
              <FormHeader type="h3" title="No Pain Entries" />{' '}
            </Segment.Group>
          )}
        </Modal.Content>
        <Modal.Actions className="participant-view-actions">
          <Link to={`/admin_entry/${participantID}`}>
            <Button positive icon="plus" labelPosition="right" content="Add Entry" />
          </Link>
          <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Close" />
        </Modal.Actions>
      </Modal>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

AdminParticipantView.propTypes = {
  studyID: PropTypes.string,
  startDate: PropTypes.string,
  entries: PropTypes.array,
  participantID: PropTypes.number,
  classifications: PropTypes.array,
  strength: PropTypes.array,
  pain: PropTypes.array,
  painReasons: PropTypes.array,
  treatmentsList: PropTypes.array,
  activityReasons: PropTypes.array,
  sources: PropTypes.array,
};
