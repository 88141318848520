import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Loader, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ActivityEntryForm from '../ActivityComponents/ActivityEntryForm';
import MorningPain from '../PainQuestionComponents/MorningPain';
import NowPain from '../PainQuestionComponents/NowPain';
import PainReasons from '../PainQuestionComponents/PainReasons';
import HighestPain from '../PainQuestionComponents/HighestPain';
import BeforeActivity from '../PainQuestionComponents/BeforeActivity';
import InterferencePain from '../PainQuestionComponents/InterferencePain';
import AfterActivity from '../PainQuestionComponents/AfterActivity';
import { fetchWithToken } from '../Util/fetch';

const components = [
  {
    comp: BeforeActivity,
    active: true,
    text: 'BeforeActivity',
    name: 'before',
  },
  {
    comp: AfterActivity,
    active: false,
    text: 'AfterActivity',
    name: 'after',
  },
  {
    comp: MorningPain,
    active: false,
    text: 'MorningPain',
    name: 'morning',
  },
  {
    comp: NowPain,
    active: false,
    text: 'NowPain',
    name: 'now',
  },
  {
    comp: HighestPain,
    active: false,
    text: 'HighestPain',
    name: 'highest',
  },
  {
    comp: PainReasons,
    active: false,
    text: 'PainReasons',
    name: 'reasons',
  },
  {
    comp: InterferencePain,
    active: false,
    text: 'InterferencePain',
    name: 'interference',
  },
];

const initState = {
  form: 'before',
  value: '',
  finished: false,
  components,
  height: '',
  index: 0,
  values: {
    before: '',
    after: '',
    morning: '',
    now: '',
    highest: '',
    reasons: [],
    interference: '',
  },
};

class ActivityCompleteForm extends Component {
  constructor(props) {
    super(props);
    this.state = { id: '', loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updatePtSub = this.updatePtSub.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const data = decoded.split('&');
    const studyID = data[1];
    this.setState({ loading: true });
    const { data: id } = await fetchWithToken().get(`/participant/id/studyID/${studyID}`);
    this.setState({ id, loading: false });
  }

  updatePtSub(values) {
    const { match } = this.props;
    const ptsubID = match.params.subID;
    const ptSubUpdateFields = {
      pain_before: values.before,
      pain_after: values.after,
      status_id: 2,
    };
    return fetchWithToken().post(`/pt_activitysub/update/id/${ptsubID}`, {
      values: ptSubUpdateFields,
    });
  }

  updatePtAct(values) {
    const { match } = this.props;
    const ptActID = match.params.visitID;
    const ptActFields = {
      morning_pain: values.morning,
      now_pain: values.now,
      highest_pain: values.highest,
      reason_pain: values.reasons.join(),
      interference_pain: values.interference,
      status_id: 2,
    };
    return fetchWithToken().post(`/pt_activity/update/visit_id/${ptActID}`, {
      values: ptActFields,
    });
  }

  handleSubmit(values) {
    axios.all([this.updatePtAct(values), this.updatePtSub(values)]);
  }

  render() {
    const resetComp = initState.components.map((comp, i) => ({
      comp: comp.comp,
      active: i < 1,
      text: comp.text,
      name: comp.name,
    }));

    initState.components = resetComp;

    const { id, loading } = this.state;
    const { match } = this.props;

    return !loading ? (
      <ActivityEntryForm
        initialState={initState}
        endForm="interference"
        titleName="Pain Questions"
        height="2900px"
        linkBack="/compliance"
        participantID={id}
        submitHandler={this.handleSubmit}
        pain
      >
        <Menu fixed="top" color="blue" widths={1}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}`} content="Home" />
        </Menu>
      </ActivityEntryForm>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(ActivityCompleteForm);

ActivityCompleteForm.propTypes = {
  match: PropTypes.any,
};
