import React, { Component } from 'react';
import { Segment, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';
import ButtonGroupForm from './ButtonGroupForm';

export default class ConditionalForm extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = { option: curVal.method, searchValue: curVal.device };
    this.formStateHandler = this.formStateHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  formStateHandler(option) {
    const { searchValue } = this.state;
    const { formHandler, conditionalValues } = this.props;
    this.setState({ option });
    if (!conditionalValues.includes(option)) {
      formHandler({ method: option, device: null, valid: true });
    } else if (searchValue) {
      formHandler({ method: option, device: searchValue, valid: true });
    } else if (conditionalValues.includes(option) && !searchValue) {
      formHandler({ method: option, device: searchValue, valid: false });
    }
  }

  handleChange(e, { value }) {
    const { option } = this.state;
    const { formHandler, conditional } = this.props;
    if (value && conditional) {
      this.setState({ searchValue: value });
      formHandler({ method: option, device: value, valid: true });
    } else if (value) {
      this.setState({ searchValue: value });
    }
  }

  render() {
    const { title, curVal, text, conditionalValues, multi, options, optionData } = this.props;
    const { option, searchValue } = this.state;
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title={title} />
          <Segment.Group raised stacked>
            <Segment color="blue">
              <ButtonGroupForm
                curVal={curVal.method}
                formStateUpdate={this.formStateHandler}
                options={options}
                multi={multi}
              />
            </Segment>
            {conditionalValues.includes(option) && (
              <Segment raised color="blue">
                <Dropdown
                  options={optionData}
                  placeholder={text}
                  search
                  selection
                  fluid
                  allowAdditions={false}
                  value={searchValue}
                  onChange={this.handleChange}
                />
              </Segment>
            )}
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

ConditionalForm.propTypes = {
  curVal: PropTypes.any,
  formHandler: PropTypes.func,
  options: PropTypes.array,
  multi: PropTypes.bool,
  title: PropTypes.string,
  optionData: PropTypes.array,
  text: PropTypes.string,
  conditional: PropTypes.bool,
  conditionalValues: PropTypes.array,
};
