import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Loader, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fetchWithToken } from '../Util/fetch';
import ActivityEntryForm from '../ActivityComponents/ActivityEntryForm';
import WeeklyQuestion from '../WeeklyQuestionsComponents/WeeklyQuestion';

const components = [
  {
    comp: WeeklyQuestion,
    active: true,
    text: 'WeeklyQuestion',
    name: 'weekly',
  },
];

const initState = {
  form: 'weekly',
  value: '',
  finished: false,
  components,
  height: '',
  index: 0,
  values: {
    weekly: {
      yesno: '',
      treatment: [],
      explain: '',
      more: [],
      done: 'no',
    },
  },
};

class WeeklyForm extends Component {
  constructor(props) {
    super(props);

    // Addin isPainContent state to add to the weekly component
    this.state = { participantID: '', isPainContent: true };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const data = decoded.split('&');
    const studyID = data[1];
    // Updating fetch url to get pt study and update isPainContent variable
    fetchWithToken()
      .get(`/participant/data/studyID/${studyID}`)
      .then(response => {
        const { id, study } = response.data;
        this.setState({ participantID: id, isPainContent: study === "CAMP" });
      });
  }

  handleSubmit(values) {
    const isUsing = values.weekly.yesno;
    const { participantID } = this.state;
    const ptOtherFields = {
      participant_id: participantID,
      is_using_other: isUsing,
      entry_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    };
    fetchWithToken()
      .post('/pt_activity_other/add', {
        fields: ptOtherFields,
      })
      .then(response => {
        if (isUsing === 2) {
          const subOtherIDArray = values.weekly.treatment.concat(values.weekly.more);
          const subOtherIDs = subOtherIDArray.join();
          const activityOtherID = response.data.activity_other_id;
          const ptActivitySubOtherFields = {
            activity_other_id: activityOtherID,
            subother_id: subOtherIDs,
            entry_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            explain: values.weekly.explain ? values.weekly.explain : null,
            is_active: 1,
          };
          fetchWithToken().post('/pt_activitysub_other/add', {
            fields: ptActivitySubOtherFields,
          });
        }
      });
  }

  render() {
    // Getting isPainContent from state
    const { participantID, isPainContent } = this.state;
    const { match } = this.props;

    // Add the isPainContent from the updated state
    const resetComp = initState.components.map((comp, i) => ({
      comp: comp.comp,
      active: i < 1,
      text: comp.text,
      name: comp.name,
    }));

    initState.components = resetComp;

    return participantID ? (
      <ActivityEntryForm
        initialState={initState}
        endForm="weekly"
        stateHandler={this.stateHandler}
        titleName="Weekly Survey"
        height="2100px"
        linkBack="/participant"
        participantID={participantID}
        submitHandler={this.handleSubmit}
        weekly
        pain={isPainContent}
      >
        <Menu fixed="top" color="blue" widths={1}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}`} content="Home" />
        </Menu>
      </ActivityEntryForm>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(WeeklyForm);

WeeklyForm.propTypes = {
  match: PropTypes.any,
};
