import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';
import ButtonGroupForm from './ButtonGroupForm';

export default class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.formStateHandler = this.formStateHandler.bind(this);
  }

  formStateHandler(option) {
    const { formHandler } = this.props;
    formHandler(option);
  }

  render() {
    const { title, curVal, options, multi } = this.props;
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title={title} />
          <Segment.Group raised stacked>
            <Segment color="blue">
              <ButtonGroupForm
                curVal={curVal}
                formStateUpdate={this.formStateHandler}
                options={options}
                multi={multi}
              />
            </Segment>
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

MultiSelect.propTypes = {
  curVal: PropTypes.any,
  formHandler: PropTypes.func,
  options: PropTypes.array,
  multi: PropTypes.bool,
  title: PropTypes.string,
};
