import React, { Component } from 'react';
import { Segment, Label, Modal, Button, List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormHeader from '../UIComponents/FormHeader';
import '../css/AdminParticipantEntryView.css';

export default class AdminParticipantWeeklyEntryView extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    const { entry, usingOther, date, treatmentsList } = this.props;

    let treatments = [];
    let otherTreatments = [];
    let anyElseTreatmnets = [];

    if (usingOther === 2) {
      treatments = entry.subother_id
        .split(',')
        .sort()
        .map(treatment => Number(treatment));
      otherTreatments = treatments.filter(treatment => treatment < 5);
      anyElseTreatmnets = treatments.filter(treatment => treatment > 4);
    }

    const entryDate = moment(date).format('MM-DD-YYYY hh:mm:ss A');
    const entryView = (
      <Segment.Group>
        <Segment>
          <FormHeader type="h3" title={`Entry on ${entryDate}`} />
        </Segment>
        <Segment>
          <Label color="blue" ribbon>
            Is Using Other
          </Label>
          {usingOther === 2 ? 'Yes' : 'No'}
        </Segment>
        {usingOther === 2 &&
          otherTreatments.length && (
            <Segment>
              <Label color="blue" ribbon>
                Treatment
              </Label>
              {treatmentsList.length && otherTreatments.map(other => treatmentsList[other - 1].description).join(', ')}
            </Segment>
          )}
        {usingOther === 2 &&
          otherTreatments.includes(4) &&
          entry.explain && (
            <Segment>
              <Label color="blue" ribbon>
                Non-Traditional Treatment Explanation
              </Label>
              {entry.explain}
            </Segment>
          )}
        {usingOther === 2 &&
          anyElseTreatmnets.length && (
            <Segment>
              <Label color="blue" ribbon>
                Additional Treatments
              </Label>
              {treatmentsList.length &&
                anyElseTreatmnets.map(other => treatmentsList[other - 1].description_other).join(', ')}
            </Segment>
          )}
      </Segment.Group>
    );
    const trigger = (
      <List.Item onClick={this.handleOpen}>
        <List.Content>
          <List.Header>{`Entry on ${entryDate}`}</List.Header>
        </List.Content>
      </List.Item>
    );

    const { open } = this.state;
    const { studyID } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose} size="tiny" trigger={trigger}>
        <Modal.Header className="participant-entry-view-header">Weekly Entry for {studyID}</Modal.Header>
        <Modal.Content scrolling>{entryView}</Modal.Content>
        <Modal.Actions className="participant-entry-view-actions">
          <Button negative onClick={this.handleClose} icon="remove" labelPosition="right" content="Close" />
        </Modal.Actions>
      </Modal>
    );
  }
}

AdminParticipantWeeklyEntryView.propTypes = {
  entry: PropTypes.object,
  usingOther: PropTypes.number,
  studyID: PropTypes.string,
  date: PropTypes.string,
  treatmentsList: PropTypes.array,
};
