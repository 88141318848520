import React from 'react';
import { Button, Segment, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import CampLayout from '../UIComponents/CampLayout';
import FormHeader from '../UIComponents/FormHeader';
import TitleHeader from '../UIComponents/TitleHeader';
import '../css/EntryOptions.css';

const EntryOptions = ({ match, prev }) => (
  <CampLayout height="100%">
    <TitleHeader type="h1" title="Survey Home" />
    <Segment.Group size="mini">
      <Segment stacked color="blue">
        <FormHeader type="h2" title="Choose An Option" />
        <Divider horizontal />
        <Link to={`/participant/${match.params.id}/entry${prev ? '/prev' : ''}`}>
          <Button fluid className="entry-options-buttons">
            Enter New Activity
          </Button>
        </Link>
        <Divider horizontal>Or</Divider>
        <Link to={`/participant/${match.params.id}/typical${prev ? '/prev' : ''}`}>
          <Button fluid className="entry-options-buttons">
            Use Typical Activty
          </Button>
        </Link>
        <Divider horizontal>Or</Divider>
        <Link to={`/participant/${match.params.id}/recent${prev ? '/prev' : ''}`}>
          <Button fluid className="entry-options-buttons">
            Use Recent Activity
          </Button>
        </Link>
        <Divider horizontal>Or</Divider>
        <Link to={`/participant/${match.params.id}${prev ? '/prev' : ''}`}>
          <Button fluid className="entry-options-buttons">
            Go Back
          </Button>
        </Link>
      </Segment>
    </Segment.Group>
  </CampLayout>
);

EntryOptions.propTypes = {
  match: PropTypes.object,
  prev: PropTypes.bool,
};

export default withRouter(EntryOptions);
