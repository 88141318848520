import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Segment, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ActivityEntryForm from '../ActivityComponents/ActivityEntryForm';
import MorningPain from '../PainQuestionComponents/MorningPain';
import NowPain from '../PainQuestionComponents/NowPain';
import PainReasons from '../PainQuestionComponents/PainReasons';
import HighestPain from '../PainQuestionComponents/HighestPain';
import BeforeActivity from '../PainQuestionComponents/BeforeActivity';
import InterferencePain from '../PainQuestionComponents/InterferencePain';
import AfterActivity from '../PainQuestionComponents/AfterActivity';
import { fetchWithToken } from '../Util/fetch';

const components = [
  {
    comp: BeforeActivity,
    active: false,
    text: 'BeforeActivity',
    name: 'before',
  },
  {
    comp: AfterActivity,
    active: false,
    text: 'AfterActivity',
    name: 'after',
  },
  {
    comp: MorningPain,
    active: true,
    text: 'MorningPain',
    name: 'morning',
  },
  {
    comp: NowPain,
    active: false,
    text: 'NowPain',
    name: 'now',
  },
  {
    comp: HighestPain,
    active: false,
    text: 'HighestPain',
    name: 'highest',
  },
  {
    comp: PainReasons,
    active: false,
    text: 'PainReasons',
    name: 'reasons',
  },
  {
    comp: InterferencePain,
    active: false,
    text: 'InterferencePain',
    name: 'interference',
  },
];

const initState = {
  form: 'morning',
  value: '',
  finished: false,
  components,
  height: '',
  index: 2,
  values: {
    morning: '',
    now: '',
    highest: '',
    reasons: [],
    interference: '',
  },
};

class PainForm extends Component {
  constructor(props) {
    super(props);

    this.state = { participantID: '', visitID: '', loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const hased = match.params.id;
    const decoded = atob(hased);
    const data = decoded.split('&');
    const studyID = data[1];
    this.setState({ loading: true });
    const participantID = await fetchWithToken().get(`/participant/id/studyID/${studyID}`);
    const { data: id } = participantID;
    const ptActFields = {
      id,
    };
    const visitIDReq = await fetchWithToken().post(`/pt_activity/none/`, ptActFields);
    const { data: visitID } = visitIDReq;
    this.setState({ participantID: id, visitID, loading: false });
  }

  handleSubmit(values) {
    const date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    const { participantID, visitID } = this.state;
    const ptActFields = {
      morning_pain: values.morning,
      now_pain: values.now,
      highest_pain: values.highest,
      reason_pain: values.reasons.join(),
      interference_pain: values.interference,
      participant_id: Number(participantID),
      activity_date: date,
      entry_date: date,
      status_id: 2,
      display: 1,
      date: moment.utc().format('YYYY-MM-DD'),
    };
    return fetchWithToken().post(`/pt_activity/update/visit_id/${visitID}`, {
      values: ptActFields,
    });
  }

  render() {
    const resetComp = initState.components.map((comp, i) => ({
      comp: comp.comp,
      active: i === 2,
      text: comp.text,
      name: comp.name,
    }));

    initState.components = resetComp;

    const { participantID, loading } = this.state;
    const { match } = this.props;

    return !loading ? (
      <ActivityEntryForm
        initialState={initState}
        endForm="interference"
        stateHandler={this.stateHandler}
        titleName="Pain Questions"
        height="2900px"
        linkBack="/compliance"
        participantID={participantID}
        submitHandler={this.handleSubmit}
        pain
      >
        <Menu fixed="top" color="blue" widths={1}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}`} content="Home" />
        </Menu>
      </ActivityEntryForm>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(PainForm);

PainForm.propTypes = {
  match: PropTypes.any,
};
