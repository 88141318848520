import React, { Component } from 'react';
import { Grid, Segment, Form, Message } from 'semantic-ui-react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import TitleHeader from '../UIComponents/TitleHeader';
import fetch from '../Util/fetch';
import '../css/VerifyParticipant.css';

class VerifyParticipant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      valid: true,
      validated: false,
      time: '',
      date: '',
      studyID: '',
      error: false,
      authFailed: false,
      notFound: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    try {
      const { match } = this.props;
      const hashed = String(match.params.id);
      const decoded = atob(hashed);
      const data = decoded.split('&');
      const studyID = data[1];
      const date = data[2];
      const time = data[3];
      this.setState({ studyID, date, time });
    } catch (e) {
      return <Redirect push to="/expired" />;
    }
  }

  handleChange(event) {
    if (event.target.value.length === 4) {
      this.setState({ error: false });
      this.handleSubmit(event.target.value);
    }
  }

  handleSubmit(value) {
    const { error, studyID } = this.state;
    if (!error) {
      this.setState({ valid: true });
      fetch
        .post(`/participant/verify/studyID/${studyID}`, {
          pin: value,
        })
        .then(response => {
          const { data } = response;
          if (data.auth) {
            this.setState({ validated: true, token: data.token });
          }
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 401) {
              this.setState({ authFailed: true });
            } else if (err.response.status === 404) {
              this.setState({ notFound: true });
            }
          }
        });
    } else {
      this.setState({ valid: false });
    }
  }

  render() {
    const error = (
      <Message negative>
        <Message.Header>ERROR : Invalid PIN</Message.Header>
      </Message>
    );

    const validInput = (
      <Message negative>
        <Message.Header>ERROR : PIN Must be 4 Digits</Message.Header>
      </Message>
    );

    const notFoundMessage = (
      <Message negative>
        <Message.Header>ERROR : Participant Not Found</Message.Header>
      </Message>
    );

    const { validated, date, time, token, authFailed, valid, notFound } = this.state;
    const { match } = this.props;

    if (validated) {
      sessionStorage.setItem('token', token);
      const { id } = match.params;
      sessionStorage.setItem('id', id);
      return <Redirect push to={`/participant/${id}`} />;
    }

    if (date && time) {
      const dateAndTime = `${date} ${time}`;
      const deadline = moment(dateAndTime);
      const expired = moment().diff(deadline, 'seconds') > 86400;
      if (expired) {
        return <Redirect push to="/expired" />;
      }
    }

    return (
      <div className="verify-form">
        <style>{`
                body > div,
                body > div > div,
                body > div > div > div.verify-form {
                    height: 100%;
                    }
                `}</style>
        <Grid className="verify-grid" textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 375 }}>
            {authFailed && error}
            {!valid && validInput}
            {notFound && notFoundMessage}
            <TitleHeader type="h1" color="blue" title="Record IMPACT" />

            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  fluid
                  className="verify-input"
                  icon="user"
                  iconPosition="left"
                  placeholder="Enter Your 4 Digit Pin"
                  pattern="[0-9]*"
                  noValidate
                  type="tel"
                  onChange={this.handleChange}
                />
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

VerifyParticipant.propTypes = {
  match: PropTypes.any,
};

export default withRouter(VerifyParticipant);
