import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import { fetchWithToken } from '../Util/fetch';
import CheckOptions from '../UIComponents/CheckOptions';

export default class ReasonForm extends Component {
  constructor(props) {
    super(props);
    this.state = { reasons: [], loading: true };
    this.stateHandler = this.stateHandler.bind(this);
  }

  componentDidMount() {
    fetchWithToken()
      .get('/activity_reason')
      .then(response => {
        const reasons = response.data.map(reason => ({ id: reason.reason_id, name: reason.description }));
        this.setState({ reasons, loading: false });
      });
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { reason } = curVal;
    const edit = !!reason.edit;
    stateHandler('reason', reason.index, { value, index: reason.index, edit, valid: true });
  }

  render() {
    const { reasons, loading } = this.state;
    const { curVal } = this.props;
    return !loading ? (
      <CheckOptions
        formHandler={this.stateHandler}
        title={
          <React.Fragment>
            <div>Reason</div>
            <div>(Please mark all that apply)</div>
          </React.Fragment>
        }
        options={reasons}
        curVal={curVal.reason.value}
        edit={!!curVal.reason.edit}
        allOrNone
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

ReasonForm.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
};
