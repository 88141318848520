import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import Tooltip from 'rc-tooltip';
import RCSlider from 'rc-slider';
import update from 'immutability-helper';
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';

const { Handle } = RCSlider;
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip prefixCls="rc-slider-tooltip" overlay={value} visible={dragging} placement="top" key={index}>
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};
export default class SliderQuestions extends Component {
  constructor(props) {
    super(props);
    const { curVal } = this.props;
    this.state = { value: curVal };
    this.handleChange = this.handleChange.bind(this);
    this.handleAfterChange = this.handleAfterChange.bind(this);
  }

  handleChange(value) {
    const newState = update(this.state, {
      value: { $set: value },
    });

    this.setState(newState);
  }

  handleAfterChange() {
    const { value } = this.state;
    const { stateHandler } = this.props;
    stateHandler(value);
  }

  render() {
    const { title, minVal, marks, curVal } = this.props;
    return (
      <Segment.Group>
        <Segment color="blue">
          <FormHeader type="h3" title={title} />
          <Segment.Group raised stacked>
            <Segment raised padded="very" color="blue">
              <RCSlider
                min={minVal ? 0 : 1}
                max={10}
                marks={marks}
                handle={handle}
                onChange={this.handleChange}
                defaultValue={curVal || null}
                onAfterChange={this.handleAfterChange}
                trackStyle={{ height: 7 }}
                handleStyle={{
                  height: 20,
                  width: 20,
                  top: 5,
                  marginLeft: -10,
                }}
                railStyle={{ height: 7 }}
              />
            </Segment>
          </Segment.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

SliderQuestions.propTypes = {
  curVal: PropTypes.any,
  stateHandler: PropTypes.func,
  title: PropTypes.string,
  marks: PropTypes.object,
  minVal: PropTypes.bool,
};

handle.propTypes = {
  value: PropTypes.number,
  dragging: PropTypes.bool,
  index: PropTypes.any,
};
