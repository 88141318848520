import React, { Component } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Card, Label, Menu, Input, Loader, Segment } from 'semantic-ui-react';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import CampLayout from '../UIComponents/CampLayout';
import TitleHeader from '../UIComponents/TitleHeader';
import AddParticipant from './AddParticipant';
import AdminParticipantView from './AdminParticipantView';
import '../css/AdminParticipantList.css';
import { fetchWithToken } from '../Util/fetch';
import ParticipantActivation from './ParticipantActivation';

class AdminParticipantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: [],
      filterdParticipants: [],
      classifications: [],
      strength: [],
      pain: [],
      painReasons: [],
      treatmentsList: [],
      activityReasons: [],
      sources: [],
      logout: false,
      loading: true,
    };
    this.handleAdd = this.handleAdd.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidMount() {
    // this.setState({ loading: true });
    const getParticipantsEntries = () => fetchWithToken().get('/participant/entries');

    const getStrength = () => fetchWithToken().get('/strength');

    const getClassification = () => fetchWithToken().get('/classification');

    const getPain = () => fetchWithToken().get('/pain_rating');

    const getPainImpact = () => fetchWithToken().get('/pain_impact');

    const getSubstanceOther = () => fetchWithToken().get('/substance/other');

    const getActivityReasons = () => fetchWithToken().get('/activity_reason');

    const getProductSources = () => fetchWithToken().get('/product_source');

    axios
      .all([
        getParticipantsEntries(),
        getClassification(),
        getStrength(),
        getPain(),
        getPainImpact(),
        getSubstanceOther(),
        getActivityReasons(),
        getProductSources(),
      ])
      .then(
        axios.spread(
          (
            entriesReq,
            classificationsReq,
            strengthReq,
            painReq,
            painReasonsReq,
            treatmentsListReq,
            activityReasonsReq,
            sourcesReq
          ) => {
            const entries = entriesReq.data;
            const classifications = classificationsReq.data;
            const strength = strengthReq.data;
            const pain = painReq.data;
            const painReasons = painReasonsReq.data;
            const treatmentsList = treatmentsListReq.data;
            const activityReasons = activityReasonsReq.data;
            const sources = sourcesReq.data;

            const addLastActive = entries.map(participant => {
              if (participant.pt_activities.length) {
                return {
                  ...participant,
                  lastActive: moment(participant.pt_activities[0].activity_date).format('YYYY-MM-DD'),
                };
              }
              return {
                ...participant,
                lastActive: '1970-01-01',
              };
            });
            const sortedParticipants = _.sortBy(addLastActive, [participant => participant.lastActive]);
            this.setState({
              participants: sortedParticipants,
              filterdParticipants: sortedParticipants,
              classifications,
              strength,
              pain,
              painReasons,
              treatmentsList,
              activityReasons,
              sources,
              loading: false,
            });
          }
        )
      );
  }

  handleAdd(value) {
    this.setState({ participants: value });
  }

  handleLogout() {
    this.setState({ logout: true });
  }

  handleSearch(event, data) {
    const { value } = data;
    const { participants } = this.state;
    const filterdParticipants = participants.filter(particpant => particpant.study_id.includes(value.toUpperCase()));
    this.setState({ filterdParticipants });
  }

  async handleRemove(participantID) {
    const { filterdParticipants } = this.state;
    const newParticipantList = filterdParticipants.filter(pt => pt.id !== participantID);
    await fetchWithToken().post('/participant/admin/activate', {
      active: 0,
      id: participantID,
    });
    this.setState({ filterdParticipants: newParticipantList });
  }

  render() {
    const {
      filterdParticipants,
      classifications,
      strength,
      pain,
      painReasons,
      treatmentsList,
      logout,
      activityReasons,
      sources,
      participants,
      loading,
    } = this.state;

    if (logout) {
      return (
        <Redirect
          push
          to={{
            pathname: '/',
            state: { logout: true },
          }}
        />
      );
    }

    const participantsList = filterdParticipants.map(participant => {
      let color = 'blue';

      if (participant.pt_activities.length) {
        const lastActivity = moment(participant.pt_activities[0].activity_date).format('YYYY-MM-DD');
        color = moment().diff(lastActivity, 'days') >= 2 ? 'red' : 'blue';
      } else {
        color = 'red';
      }

      const daysOnStudy = moment().diff(participant.start_date, 'days') + 1;
      return (
        classifications.length &&
        strength.length &&
        pain.length &&
        painReasons.length &&
        treatmentsList.length &&
        activityReasons.length && (
          <Card fluid raised color="blue" key={`card-${participant.id}`}>
            <Card.Content>
              <Card.Header textAlign="left" className="card-header">
                StudyID : {participant.study_id}
              </Card.Header>
              <Card.Meta className="card-status">
                Status : {`Day ${daysOnStudy || 0}`}
                <Label className="card-label" color={color} horizontal>
                  {participant.pt_activities.length
                    ? `Last Active : ${moment(participant.pt_activities[0].activity_date).format('MM-DD-YYYY')}`
                    : 'No Activity'}
                </Label>
              </Card.Meta>
            </Card.Content>
            <Card.Content extra>
              <AdminParticipantView
                studyID={participant.study_id}
                startDate={participant.start_date}
                entries={participant.pt_profiles}
                participantID={participant.id}
                strength={strength}
                classifications={classifications}
                pain={pain}
                painReasons={painReasons}
                treatmentsList={treatmentsList}
                activityReasons={activityReasons}
                sources={sources}
              />
              <ParticipantActivation
                participantID={participant.id}
                studyID={participant.study_id}
                activationHandler={this.handleRemove}
                triggerText="Deactivate Participant"
                confirmationText="Confirm Deactivation of Participant"
                deactivate
              />
            </Card.Content>
          </Card>
        )
      );
    });

    return !loading ? (
      <CampLayout height="100%">
        <Menu fixed="top" widths={4}>
          <Menu.Item header>CAMP</Menu.Item>
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={this.handleSearch} />
          </Menu.Item>
          <Menu.Item as={Link} to="/inactive" content="Inactive Participants" />
          <Menu.Item onClick={this.handleLogout} content="Logout" />
        </Menu>
        <TitleHeader type="h2" title="Participants" />
        <Card.Group className="card-group">
          {participantsList.length ? (
            participantsList
          ) : (
            <Card fluid raised color="blue">
              <Card.Content>
                <Card.Header textAlign="center" className="card-header">
                  No Active Participants
                </Card.Header>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
        <div className="add-submit">
          <AddParticipant participants={participants} handleAdd={this.handleAdd} />
        </div>
      </CampLayout>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(AdminParticipantList);
