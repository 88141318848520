import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { fetchWithToken } from '../Util/fetch';
import ConditionalForm from '../UIComponents/ConditionalForm';

export default class MethodForm extends Component {
  constructor(props) {
    super(props);
    this.state = { methods: [], options: [], loading: true };
    this.stateHandler = this.stateHandler.bind(this);
  }

  async componentDidMount() {
    const { data: unformattedMethods } = await fetchWithToken().get('/method');
    const { data: unformattedDevices } = await fetchWithToken().get('/device');
    const methods = unformattedMethods.map(method => ({ id: method.method_id, name: method.description }));
    const options = _.orderBy(unformattedDevices, 'priority', 'desc').map((option, i) => ({
      key: i,
      text: option.description,
      value: option.device_id,
    }));
    this.setState({ methods, options, loading: false });
  }

  stateHandler(value) {
    const { curVal, stateHandler } = this.props;
    const { method } = curVal;
    stateHandler('method', method.index, { ...value, index: method.index });
  }

  render() {
    const { methods, options, loading } = this.state;
    const { curVal } = this.props;

    return !loading ? (
      <ConditionalForm
        formHandler={this.stateHandler}
        title="Select Method"
        options={methods}
        curVal={curVal.method}
        multi={false}
        optionData={options}
        text="Pick a Device"
        conditional
        conditionalValues={[1, 2, 3]}
      />
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

MethodForm.propTypes = {
  stateHandler: PropTypes.func,
  curVal: PropTypes.any,
};
