import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Loader, Segment, Menu, List } from 'semantic-ui-react';
import moment from 'moment';
import { fetchWithToken } from '../Util/fetch';
import ActivityList from '../UIComponents/ActivityList';
import ActivityEntryView from '../UIComponents/ActivityEntryView';

class TypicalActivities extends Component {
  constructor(props) {
    super(props);
    this.state = { activities: [], classifications: [], loading: true };
  }

  async componentDidMount() {
    const { match } = this.props;

    const hased = match.params.id;
    const decoded = atob(hased);
    const splitData = decoded.split('&');
    const studyID = splitData[1];

    const { data: id } = await fetchWithToken().get(`/participant/id/studyID/${studyID}`);

    const typicalUrl = `/participant/entries/${id}`;

    const { data: activities } = await fetchWithToken().get(typicalUrl);
    const { data: classifications } = await fetchWithToken().get('/classification');
    this.setState({ activities, classifications, loading: false });
  }

  render() {
    const { activities, classifications, loading } = this.state;
    const { match, update, prev } = this.props;
    let activityList = [];
    if (activities.length) {
      activityList = activities[0].pt_profiles.map(activity => (
        <List.Item key={`typical-${activity.id}`}>
          <List.Header>
            <ActivityEntryView
              entry={activity}
              header={activity.substances[0].name}
              title={`Entered on ${moment(activity.entry_date).format('MM-DD-YYYY HH:mm:ss')}`}
              participantID={match.params.id}
              entryID={activity.id}
              editType="typical"
              typicalUpdate={update}
              classifications={classifications}
              prev={prev}
            />
          </List.Header>
        </List.Item>
      ));
    }

    return !loading ? (
      <ActivityList
        typicalUpdate
        activities={activityList}
        title="Typical Activities"
        subtitle="List of Typical Activities"
        prev={prev}
      >
        <Menu fixed="top" color="blue" widths={update === true ? 1 : 2}>
          <Menu.Item as={Link} to={`/participant/${match.params.id}${prev ? '/prev' : ''}`} content="Home" />
          {!update && (
            <Menu.Item as={Link} to={`/participant/${match.params.id}/recent${prev ? '/prev' : ''}`} content="Recent" />
          )}
        </Menu>
      </ActivityList>
    ) : (
      <Segment>
        <Loader active inline="centered" />
      </Segment>
    );
  }
}

export default withRouter(TypicalActivities);

TypicalActivities.propTypes = {
  match: PropTypes.object,
  update: PropTypes.bool,
  prev: PropTypes.bool,
};
