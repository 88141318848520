import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../css/ButtonForm.css';

export default class ButtonForm extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(value) {
    const { formClickHandler } = this.props;
    formClickHandler(value);
  }

  render() {
    const { activeState, value, buttonName } = this.props;
    return (
      <Button active={activeState} onClick={() => this.clickHandler(value)} className="button-options">
        {buttonName}
      </Button>
    );
  }
}

ButtonForm.propTypes = {
  activeState: PropTypes.bool,
  buttonName: PropTypes.string,
  formClickHandler: PropTypes.func,
  value: PropTypes.number,
};
